import React from 'react';
import styled from 'styled-components';

const NotificationWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 25px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
  
  ${({ type }) => {
    switch (type) {
      case 'success':
        return 'background-color: #4CAF50;';
      case 'error':
        return 'background-color: #f44336;';
      default:
        return 'background-color: #2196F3;';
    }
  }}

  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const Notification = ({ message, type }) => {
  return (
    <NotificationWrapper type={type}>
      {message}
    </NotificationWrapper>
  );
};

export default Notification;
