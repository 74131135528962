import React from 'react';
import styled from 'styled-components';
import { BiTime, BiGlobe } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import './Header.css';

const ServiceText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Almarai', sans-serif;
  font-weight: 400;
  font-size: 14px;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const RegularText = styled.span`
  color: #183457;
`;

const HoursText = styled.span`
  color: #C4261E;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  padding: 0 16px;
`;

const LanguageSelector = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  color: #183457;
  font-family: 'Almarai', sans-serif;
  font-size: 14px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(24, 52, 87, 0.05);
  }

  svg {
    font-size: 16px;
  }
`;

const Header = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  const toggleLanguage = () => {
    const newLang = i18n.language === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(newLang);
    document.documentElement.dir = newLang === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.lang = newLang;
  };

  return (
    <header className="header">
      <div className="container" style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
        <HeaderContent isRTL={isRTL}>
          <LanguageSelector onClick={toggleLanguage}>
            <BiGlobe />
            {isRTL ? 'English' : 'العربية'}
          </LanguageSelector>
          <ServiceText isRTL={isRTL}>
            <BiTime style={{ color: '#C4261E' }} />
            {isRTL ? (
              <>
                <RegularText>{t('header.service-text')}</RegularText>
                <HoursText>{t('header.service-hours')}</HoursText>
              </>
            ) : (
              <RegularText>{t('header.service-text')}</RegularText>
            )}
          </ServiceText>
        </HeaderContent>
      </div>
    </header>
  );
};

export default Header;
