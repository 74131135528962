import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavigationContainer = styled.div`
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  direction: ${(props) => (props.isRTL ? "rtl" : "ltr")};
`;

const NavigationContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: "Almarai", sans-serif;
`;

const NavLink = styled(Link)`
  color: #6c757d;
  text-decoration: none;
  font-size: 1.1rem;

  &:hover {
    color: #007bff;
  }
`;

const Separator = styled.span`
  color: #6c757d;
  margin: 0 0.5rem;
`;

const CurrentPage = styled.span`
  color: #212529;
  font-size: 1.1rem;
`;

const PageNavigation = ({ currentPage }) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";

  return (
    <NavigationContainer isRTL={isRTL}>
      <NavigationContent>
        {isRTL ? (
          <>
            <NavLink to="/">{t("mainPage.title")}</NavLink>
            <Separator>{">"}</Separator>
            <CurrentPage>{currentPage}</CurrentPage>
          </>
        ) : (
          <>
            <CurrentPage>{currentPage}</CurrentPage>
            <Separator>{"<"}</Separator>
            <NavLink to="/">{t("mainPage.title")}</NavLink>
          </>
        )}
      </NavigationContent>
    </NavigationContainer>
  );
};

export default PageNavigation;
