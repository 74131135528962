import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import doctorsImage from '../../assets/doctors.png';
import frameImage from '../../assets/Frame doctors.png';
import branchesIcon from '../../assets/icons/branches.png';
import specialtiesIcon from '../../assets/icons/specialties.png';
import doctorsIcon from '../../assets/icons/doctors.png';

const slideInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideOutUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
  max-width: 1400px;
  margin: 0 auto;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  font-family: 'Almarai', sans-serif;

  @media (max-width: 968px) {
    flex-direction: column-reverse;
    padding: 2rem;
    text-align: center;
  }
`;

const ContentSection = styled.div`
  flex: 0.8;
  padding: ${props => props.isRTL ? '0 0 0 2rem' : '0 2rem 0 0'};
  max-width: 600px;

  @media (max-width: 968px) {
    padding: 2rem 0 0 0;
    max-width: 100%;
  }
`;

const MainTitle = styled.h1`
  font-size: 3rem;
  color: #183457;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1.2;
  width: 100%;
  font-family: 'Almarai', sans-serif;

  .highlight-wrapper {
    display: inline-block;
    min-width: 150px;
    text-align: center;
  }

  .highlight {
    color: #C4261E;
    display: inline-block;
    margin: 0 0.5rem;
    animation: ${props => props.isEntering ? slideInDown : slideOutUp} 0.5s ease forwards;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
  font-family: 'Almarai', sans-serif;
`;

const BookButton = styled.button`
  background-color: #183457;
  color: white;
  border: none;
  padding: 0.8rem 3rem;
  font-size: 1.25rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Almarai', sans-serif;
  font-weight: 500;
  box-shadow: 0 4px 6px rgba(24, 52, 87, 0.2);

  &:hover {
    background-color: #122843;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(24, 52, 87, 0.3);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(24, 52, 87, 0.2);
  }
`;

const ImageSection = styled.div`
  flex: 1;
  position: relative;

  .stats {
    position: absolute;
    background: white;
    padding: 0.5rem 1rem;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    box-shadow: 0 4px 15px rgba(0,0,0,0.08);
    z-index: 2;
    min-width: 180px;
  }

  .stats-content {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    text-align: ${props => props.isRTL ? 'right' : 'left'};
    font-family: 'Almarai', sans-serif;
  }

  .stats-number {
    font-size: 1.1rem;
    font-weight: bold;
    color: #183457;
    line-height: 1;
    font-family: 'Almarai', sans-serif;
  }

  .stats-text {
    font-size: 0.85rem;
    color: #666;
    line-height: 1;
    font-family: 'Almarai', sans-serif;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F6F9FC;
    border-radius: 12px;
    flex-shrink: 0;
    width: 36px;
    height: 36px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .branches {
    top: 33%;
    ${props => props.isRTL ? 'right' : 'left'}: -5%;
  }

  .specialties {
    bottom: 35%;
    ${props => props.isRTL ? 'left' : 'right'}: -20px;
  }

  .doctors {
    bottom: 10%;
    ${props => props.isRTL ? 'right' : 'left'}: -5%;
  }

  @media (max-width: 968px) {
    width: 100%;
    max-width: 500px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const FrameImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
`;

const DoctorsImage = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
`;

const Hero = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [currentHighlight, setCurrentHighlight] = useState(0);
  const [isEntering, setIsEntering] = useState(true);
  const [stats, setStats] = useState({
    branches: 0,
    doctors: 0,
    specialties: 0
  });
  const highlights = t('mainPage.hero.highlights', { returnObjects: true });

  useEffect(() => {
    const fetchStats = async () => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      if (!apiUrl) {
        console.error('API Base URL is not defined in environment variables');
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/statistics/main-page`);
        const data = await response.json();
        setStats({
          branches: data.mainPage.heroSection.branches,
          doctors: data.mainPage.heroSection.doctors,
          specialties: data.mainPage.heroSection.specialties
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsEntering(false);
      setTimeout(() => {
        setCurrentHighlight((prev) => (prev + 1) % highlights.length);
        setIsEntering(true);
      }, 500);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [highlights.length]);

  const renderTitle = () => {
    return (
      <>
        {t('mainPage.hero.title')}
        <div className="highlight-wrapper">
          <span className="highlight" key={currentHighlight}>
            {highlights[currentHighlight]}
          </span>
        </div>
      </>
    );
  };

  return (
    <HeroContainer isRTL={isRTL}>
      <ContentSection isRTL={isRTL}>
        <MainTitle isRTL={isRTL} isEntering={isEntering}>
          {renderTitle()}
        </MainTitle>
        <Description>
          {t('mainPage.hero.description')}
        </Description>
        <BookButton>
          {t('mainPage.hero.book_appointment')}
        </BookButton>
      </ContentSection>
      
      <ImageSection isRTL={isRTL}>
        <ImageWrapper>
          <FrameImage src={frameImage} alt="" />
          <DoctorsImage src={doctorsImage} alt={t('mainPage.hero.image_alt')} />
        </ImageWrapper>
        <div className="stats branches">
          <div className="icon">
            <img src={branchesIcon} alt="" />
          </div>
          <div className="stats-content">
            <div className="stats-number">+{stats.branches}</div>
            <div className="stats-text">{t('mainPage.hero.medical_branches')}</div>
          </div>
        </div>
        <div className="stats specialties">
          <div className="icon">
            <img src={specialtiesIcon} alt="" />
          </div>
          <div className="stats-content">
            <div className="stats-number">+{stats.specialties}</div>
            <div className="stats-text">{t('mainPage.hero.specialties')}</div>
          </div>
        </div>
        <div className="stats doctors">
          <div className="icon">
            <img src={doctorsIcon} alt="" />
          </div>
          <div className="stats-content">
            <div className="stats-number">+{stats.doctors}</div>
            <div className="stats-text">{t('mainPage.hero.doctors')}</div>
          </div>
        </div>
      </ImageSection>
    </HeroContainer>
  );
};

export default Hero;
