import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Get saved language from localStorage or default to 'ar'
const savedLanguage = localStorage.getItem("language") || "ar";
const isRTL = savedLanguage === "ar";

// Set HTML dir attribute based on language
document.documentElement.dir = isRTL ? "rtl" : "ltr";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        mainPage: {
          title: "Home",
          hero: {
            title: "Best Doctors at Your Fingertips",
            highlights: ["Care", "Medical", "Complete"],
            description:
              "We always strive to provide comprehensive medical care and attention because you are our first priority, and your care is our first duty, and we seek your satisfaction and comfort",
            book_appointment: "Book Appointment",
            image_alt: "Tadawi Medical Doctors",
            medical_branches: "Medical Branches",
            specialties: "Medical Specialties",
            doctors: "Professional Doctors",
          },
          about: {
            title: "About Us",
            description:
              "A medical company that provides an integrated range of medical, therapeutic and cosmetic services. Tadawi Medical Company is one of the leading medical companies in the region that seeks to provide high-quality medical services in various medical specialties.",
            vision: {
              title: "Our Vision",
            },
            goals: {
              title: "Our Goals",
            },
            mission: {
              title: "Our Mission",
            },
            readMore: "Read More",
          },
          services: {
            title: "Our Departments and Medical Services",
            description:
              "From routine check-ups to advanced treatments, we are committed to providing comprehensive healthcare services that put your health first.",
            departments: {
              title: "Our Medical Departments",
              description:
                "We provide more than 15 integrated medical departments, including specialties such as pediatrics, cardiology, and general surgery.",
            },
            homecare: {
              'title': 'Home Healthcare',
              'description': 'We provide home care service to provide medical care in the comfort of your home, whether you need medical follow-up or elderly care.'
            },
            facilities: {
              'title': 'Other Facilities',
              'description': 'The pharmacy and emergency are available around the clock to meet your urgent needs and ensure your safety.'
            }
          },
          booking: {
            title: "Your comfort journey starts here",
            description:
              "Whether you're looking for a medical consultation, treatment follow-up, or routine check-up, we're here to make your healthcare journey easier.",
            process:
              "Save your time and effort with our quick and easy appointment booking service. In simple steps, you can choose the appropriate medical specialty, select your preferred doctor, and book an appointment at a time that suits you.",
            button: "Book Appointment",
            badge: "Specialized Doctors",
          },
          store: {
            title: "Store",
            description:
              "A medical company that provides an integrated range of medical, therapeutic and cosmetic services. Tadawi Medical Company is one of the advanced medical companies in the region that seeks to provide high-quality medical services in various medical specialties",
            readMore: "Read More",
          },
          offers: {
            title: "Special Offers",
            description: "Discover our exclusive medical offers and promotions",
            noOffers: "No offers available at the moment",
            readMore: "Read More",
          },
        },
        header: {
          "service-text": "24/7 Service All Week",
          profile: "Profile",
          appointments: "My Appointments",
          logout: "Logout",
          login: "Login"
        },
        InnerHeader: {
          "about-group": "About Group",
          about: "About Us",
          team: "Our Team",
          history: "Our History",
          values: "Our Values",
          aboutDescription: "Learn about our mission, vision, and commitment to healthcare excellence",
          teamDescription: "Meet our dedicated team of healthcare professionals",
          historyDescription: "Discover our journey and milestones in healthcare",
          valuesDescription: "Explore the core values that drive our healthcare services",
          mission: "Our Mission",
          vision: "Our Vision",
          doctors: "Our Doctors",
          staff: "Medical Staff",
          timeline: "Timeline",
          achievements: "Achievements",
          coreValues: "Core Values",
          commitment: "Our Commitment",
          "medical-services": "Medical Services",
          "book-appointment": "Book Appointment",
          store: "Store",
          offers: "Our Offers",
          "medical-info": "Medical Info"
        },
        chat: "Start Chat",
        cookies: {
          message:
            "We use cookies to enhance your experience on our website, for analytical and marketing purposes. You can choose to accept all cookies or customize your preferences. For more details, please review our Privacy Policy.",
          acceptAll: "Accept All Cookies",
          customize: "Customize Preferences",
          reject: "Reject Non-Essential Cookies",
          close: "Close",
        },
        footer: {
          description:
            "We always strive to provide comprehensive medical care and attention because you are our first priority, and your care is our first duty, and we seek your satisfaction and comfort",
          "follow-us": "Follow Us",
          "quick-links": "Quick Links",
          "additional-links": "Additional Links",
          "customer-support": "Customer Support",
          home: "Home",
          about: "About Us",
          "medical-services": "Medical Services",
          "book-appointment": "Book Appointment",
          branches: "Our Branches",
          "home-care": "Home Healthcare",
          store: "Store",
          careers: "Careers",
          "medical-info": "Medical Information",
          faq: "FAQ",
          complaints: "Complaints",
          "support-center": "Support Center",
          "privacy-policy": "Privacy Policy",
          terms: "Terms of Use",
          copyright: " Copyright reserved for Al-Tadawi Medical Complex",
        },
        login: {
          title: "Login",
          identifier: "ID Number or Residence Number",
          identifierPlaceholder: "Enter your ID or residence number",
          phone: "Phone Number",
          phonePlaceholder: "Enter your phone number",
          submit: "Login",
          noAccount: "Don't have an account?",
          register: "Create Account",
          otpVerification: "Phone Verification",
          otpMessage: "Enter the 6-digit code sent to",
          verifyOTP: "Verify Code",
          resendOTP: "Resend Code",
          resendCode: "Resend code in",
          continue: "Continue",
          pageTitle: "Login",
          email: "Email",
          password: "Password",
          loading: "Logging in...",
          error: "Invalid email or password"
        },
        register: {
          title: "Create Account",
          identifier: "ID Number or Residence Number",
          identifierPlaceholder: "Enter your ID or residence number",
          phone: "Phone Number",
          firstName: "First Name",
          firstNamePlaceholder: "Enter your first name",
          lastName: "Last Name",
          lastNamePlaceholder: "Enter your last name",
          email: "Email",
          emailPlaceholder: "Enter your email",
          password: "Password",
          passwordPlaceholder: "Enter your password",
          confirmPassword: "Confirm Password",
          confirmPasswordPlaceholder: "Confirm your password",
          phonePlaceholder: "Enter your phone number",
          submit: "Create Account",
          haveAccount: "Already have an account?",
          login: "Login",
          otpMessage: "Enter the verification code sent to your phone",
          verifyOTP: "Verify Code",
          resendOTP: "Resend Code",
          otpError: "Invalid verification code",
          otpSuccess: "Verification code is valid"
        },
        msg:{
          otpsentsuccessfully: "OTP sent successfully",
          otpverify: "OTP verified successfully",
          otpsentfailed:"Failed to send OTP",
          otpsentexpired:"OTP send failed",
          otpverifyfailed:"OTP verification failed",
          otpverifyexpired:"OTP verification failed",
          otpSent: "Verification code sent successfully",
          otpVerified: "Code verified successfully",
          otpFailed: "Invalid verification code",
          otpSendFailed: "Failed to send verification code",
          loginSuccess: "Logged in successfully",
          loginFailed: "Login failed",
          userNotActive: "Please complete your registration first",
          registrationComplete: "Registration completed successfully",
          registrationFailed: "Registration failed",
          passwordMismatch: "Passwords do not match"
        },
        medicalInfo: {
          title: 'Medical Information',
          description: 'We provide you with a collection of reliable and comprehensive medical articles to be a rich source of health information you need.',
          readMore: 'Read More',
          viewMore: 'View More',
          article1: {
            title: 'Can a Thalassemia Carrier Donate Blood?',
            description: 'Folic acid screening protects the fetus from serious birth defects and is available in vitamins and fortified foods, such as bread...'
          },
          article2: {
            title: 'Risks of Treating Nasal Congestion in Infants with Olive Oil',
            description: 'Folic acid screening protects the fetus from serious birth defects and is available in vitamins and fortified foods, such as bread...'
          }
        },
        healthCare: {
          title: 'Health Care',
          description: 'Tadawi Medical Group provides home care services to ensure comfortable and safe healthcare for patients in their homes. Our services include',
          service1: {
            title: 'Intensive Care Patient Care',
            description: 'Specialized team to provide medical care for patients who need careful monitoring and special care.'
          },
          service2: {
            title: 'Intensive Care Patient Care',
            description: 'Specialized team to provide medical care for patients who need careful monitoring and special care.'
          },
          service3: {
            title: 'Intensive Care Patient Care',
            description: 'Specialized team to provide medical care for patients who need careful monitoring and special care.'
          }
        },
        joinUs: {
          title: "Join Our Medical Network",
          description: "Are you a healthcare professional looking to make a difference? Join our growing network of medical providers.",
          subDescription: "We offer competitive compensation, flexible schedules, and the opportunity to be part of a leading healthcare platform.",
          button: "Join Our Team"
        },
        testimonials: {
          testimonial1: {
            text: "The service at Tadawi Medical Center was exceptional. The staff was professional and caring, making my experience comfortable and reassuring.",
            author: "Sarah Johnson"
          },
          clinicImage: "Clinic Interior"
        },
        faq: {
          title: "Frequently Asked Questions",
          description: "Our FAQ section is designed to provide you with quick and comprehensive answers to common inquiries.",
          question1: "How can I book an appointment with a doctor through the website?",
          answer1: "You can easily book an appointment by visiting our doctors page, selecting your preferred doctor, choosing an available time slot, and filling out the booking form. You'll receive a confirmation email once your appointment is scheduled.",
          question2: "What specialties are available at your medical center?",
          answer2: "We offer a wide range of medical specialties including Internal Medicine, Pediatrics, Cardiology, Dermatology, Orthopedics, and more. Visit our specialties page to see the complete list.",
          question3: "How can I view my medical history and previous appointments?",
          answer3: "After logging into your account, you can access your medical history, view past appointments, and download medical reports from your patient dashboard.",
          question4: "What payment methods do you accept?",
          answer4: "We accept various payment methods including credit cards, debit cards, and insurance coverage. Payment details can be handled securely through our website.",
          question5: "Can I cancel or reschedule my appointment?",
          answer5: "Yes, you can cancel or reschedule your appointment through your account dashboard up to 24 hours before the scheduled time. Please refer to our cancellation policy for more details.",
          error: "Unable to load FAQ data. Please try again later."
        },
        bookingPage: {
          pageTitle: "Appointment Booking",
          title: "Book Your Appointment",
          searchPlaceholder: "Search for doctors...",
          filter: {
            reset: "Reset filters",
            branch: "Select Branch",
            degree: "Select Degree",
            price: "Select Price Range",
            type: "Select Type"
          }
        },
        common: {
          loading: "Loading ...",
          error: "An error occurred. Please try again.",
        },
        doctorCard: {
          reviews: "Reviews",
          branch: "Branch",
          degree: "Degree",
          price: "Price",
          currency: "SAR",
          type: "Specialty",
          nextAvailable: "Next Available",
          bookNow: "Book Now",
        },
        bookingWizard: {
          selectDoctor: "Select Doctor",
          selectDateTime: "Select Date & Time",
          enterDetails: "Enter Details",
          payment: "Payment",
          experience: "{{years}} years of experience",
          consultationFee: "per consultation",
          patientName: "Patient Name",
          patientNamePlaceholder: "Enter patient's full name",
          patientPhone: "Phone Number",
          patientPhonePlaceholder: "Enter phone number",
          paymentOptions: {
            creditCard: "Credit Card",
            cash: "Cash at Clinic"
          },
          back: "Back",
          next: "Next",
          finish: "Finish",
          calendar: {
            weekDays: {
              friday: "Friday",
              saturday: "Saturday",
              sunday: "Sunday",
              monday: "Monday",
              tuesday: "Tuesday",
              wednesday: "Wednesday",
              thursday: "Thursday"
            },
            months: {
              september: "September"
            }
          }
        }
      },
    },
    ar: {
      translation: {
        mainPage: {
          title:"الرئيسية",
          hero: {
            title: "أفضل الأطباء في متناول يديك تداوي",
            highlights: ["عناية", "طبية", "متكاملة"],
            description:
              "نسعى دائماً لتقديم رعاية وعناية طبية متكاملة لانكم اول اهتماماتنا ورعايتكم اول واجباتنا ونسعى لرضاكم وراحتكم",
            book_appointment: "احجز موعدك",
            image_alt: "أطباء تداوي الطبي",
            medical_branches: "فروع طبية",
            specialties: "تخصص طبي",
            doctors: "أطباء محترفون",
          },
          about: {
            title: "من نحن",
            description:
              "شركة طبية تقدم مجموعة متكاملة من الخدمات الطبية والعلاجية و التجميلية، وتعتبر شركة تداوي الطبي أحدى الشركات الطبية المتقدمة في المنطقة تسعى لتقديم خدمات طبية عالية الجودة في مختلف التخصصات الطبية",
            vision: {
              title: "رؤيتنا",
            },
            goals: {
              title: "أهدافنا",
            },
            mission: {
              title: "رسالتنا",
            },
            readMore: "اقرأ المزيد",
          },
          services: {
            title: "أقسامنا وخدماتنا الطبية",
            description:
              "من الفحوصات الروتينية إلى العلاجات المتقدمة، نحن ملتزمون بتقديم خدمات صحية شاملة تضع صحتك في المقام الأول.",
            departments: {
              title: "أقسامنا الطبية",
              description:
                "نقدم أكثر من 15 قسمًا طبيًا متكاملاً، بما في ذلك تخصصات مثل طب الأطفال وأمراض القلب و الجراحة العامة.",
            },
            homecare: {
              'title': 'رعاية صحية منزلية',
              'description': 'نقدم خدمة الرعاية المنزلية لتوفير الرعاية الصحية المريحة والآمنة للمرضى في منازلهم. تشمل خدماتنا'
            },
            facilities: {
              'title': 'مرافق أخرى',
              'description': 'الصيدلية والطوارئ لدينا متاح على مدار الساعة لتلبية احتياجاتك العاجلة و لضمان سلامتك'
            }
          },
          booking: {
            title: "راحة بالك تبدأ من هنا",
            description:
              "سواء كنت تبحث عن استشارة طبية، متابعة علاج، أو فحص روتيني نحن هنا لتسهيل رحلتك الصحية.",
            process:
              "وفر وقتك وجهدك مع خدمة حجز المواعيد السريعة والسهلة. في خطوات بسيطة، يمكنك اختيار التخصص الطبي المناسب، تحديد الطبيب المفضل لديك، وحجز الموعد في الوقت الذي يناسبك.",
            button: "احجز موعدك",
            badge: "اطباء متخصصون",
          },
          store: {
            title: "المتجر",
            description:
              "شركة طبية تقدم مجموعة متكاملة من الخدمات الطبية والعلاجية و التجميلية، وتعتبر شركة تداوي الطبي إحدى الشركات الطبية المتقدمة في المنطقة تسعى لتقديم خدمات طبية عالية الجودة في مختلف التخصصات الطبية",
            readMore: "اقرأ المزيد",
          },
          offers: {
            title: "العروض الخاصة",
            description: "اكتشف عروضنا وخصوماتنا الطبية الحصرية",
            noOffers: "لا توجد عروض متاحة حالياً",
            readMore: "اقرأ المزيد",
          },
        },
        header: {
          "service-text": "خدمة طيلة أيام الأسبوع",
          "service-hours": "24 ساعة",
          profile: "الملف الشخصي",
          appointments: "مواعيدي",
          logout: "تسجيل الخروج",
          login: "تسجيل الدخول"
        },
        InnerHeader: {
          "about-group": "عن المجموعة",
          about: "عن الشركة",
          team: "فريق العمل",
          history: "تاريخنا",
          values: "قيمنا",
          aboutDescription: "تعرف على رسالتنا ورؤيتنا والتزامنا بالتميز في الرعاية الصحية",
          teamDescription: "تعرف على فريقنا المتخصص من المهنيين الصحيين",
          historyDescription: "اكتشف رحلتنا وإنجازاتنا في مجال الرعاية الصحية",
          valuesDescription: "اكتشف القيم الأساسية التي تقود خدماتنا الصحية",
          mission: "رسالتنا",
          vision: "رؤيتنا",
          doctors: "أطباؤنا",
          staff: "الطاقم الطبي",
          timeline: "الجدول الزمني",
          achievements: "إنجازاتنا",
          coreValues: "قيمنا الأساسية",
          commitment: "التزامنا",
          "medical-services": "الأقسام والخدمات الطبية",
          "book-appointment": "حجز موعد",
          store: "المتجر",
          offers: "عروضنا",
          "medical-info": "معلومات طبية"
        },
        chat: "بدء المحادثة",
        cookies: {
          message:
            "نحن نستخدم ملفات تعريف الارتباط (الكوكيز) لتحسين تجربتك على الموقع، ولأغراض تحليلية وتسويقية. يمكنك اختيار قبول جميع الكوكيز أو تخصيص تفضيلاتك. لمزيد من التفاصيل، يُرجى مراجعة سياسة الخصوصية.",
          acceptAll: "قبول جميع الكوكيز",
          customize: "تخصيص التفضيلات",
          reject: "رفض الكوكيز غير الضرورية",
          close: "إغلاق",
        },
        footer: {
          description:
            "نسعى دائماً لتقديم رعاية وعناية طبية متكاملة لانكم اول اهتماماتنا ورعايتكم اول واجباتنا ونسعى لرضاكم وراحتكم",
          "follow-us": "تابعنا على",
          "quick-links": "الروابط السريعة",
          "additional-links": "روابط إضافية",
          "customer-support": "دعم العملاء",
          home: "الرئيسية",
          about: "عن المجموعة",
          "medical-services": "الأقسام والخدمات الطبية",
          "book-appointment": "حجز موعد",
          branches: "فروعنا",
          "home-care": "رعاية صحية منزلية",
          store: "المتجر",
          careers: "الوظائف",
          "medical-info": "معلومات طبية",
          faq: "الاسئلة الشائعة",
          complaints: "الشكاوي",
          "support-center": "مركز الدعم",
          "privacy-policy": "سياسة الخصوصية",
          terms: "شروط الاستخدام",
          copyright: " حقوق النشر محفوظة لمجمع التداوي الطبي",
        },
        login: {
          title: "تسجيل الدخول",
          identifier: "رقم الهوية او رقم الإقامة",
          identifierPlaceholder: "ادخل رقم الهوية او رقم الإقامة",
          phone: "رقم الجوال",
          phonePlaceholder: "ادخل رقم الجوال",
          submit: "تسجيل الدخول",
          noAccount: "ليس لديك حساب؟",
          register: "انشاء حساب",
          otpVerification: "التحقق من رقم الجوال",
          otpMessage: "أدخل رمز التحقق المكون من 6 أرقام والذي تم إرساله إلى",
          verifyOTP: "تحقق من الرمز",
          resendOTP: "إعادة إرسال الرمز",
          resendCode: "أعد أرسل رمز التحقق بعد",
          continue: "متابعة",
          pageTitle: "تسجيل الدخول",
          email: "البريد الإلكتروني",
          password: "كلمة المرور",
          loading: "جاري تسجيل الدخول...",
          error: "البريد الإلكتروني أو كلمة المرور غير صحيحة"
        },
        register: {
          title: "انشاء حساب",
          identifier: "رقم الهوية او رقم الإقامة",
          identifierPlaceholder: "ادخل رقم الهوية او رقم الإقامة",
          firstName: "الاسم الاول",
          firstNamePlaceholder: "ادخل الاسم الاول",
          lastName: "الاسم الاخير",
          lastNamePlaceholder: "ادخل الاسم الاخير",
          email: "البريد الالكتروني",
          emailPlaceholder: "ادخل البريد الالكتروني",
          password: "كلمة المرور",
          passwordPlaceholder: "ادخل كلمة المرور",
          confirmPassword: "تاكيد كلمة المرور",
          confirmPasswordPlaceholder: "ادخل تاكيد كلمة المرور",

          phone: "رقم الجوال",
          phonePlaceholder: "ادخل رقم الجوال",
          submit: "انشاء حساب",
          haveAccount: "لديك حساب؟",
          login: "تسجيل الدخول",
          otpMessage: "ادخل رمز التحقق المرسل إلى جوالك",
          verifyOTP: "تحقق من الرمز",
          resendOTP: "إعادة إرسال الرمز",
          otpError: "رمز التحقق غير صحيح",
          otpSuccess: "رمز التحقق صحيح"
        },
        msg:{
          otpsentsuccessfully:"تم ارسال رمز التحقق بنجاح",
          otpsentfailed:"فشل ارسال رمز التحقق",
          otpsentexpired:"فشل ارسال رمز التحقق",
          otpverify:"تم التحقق من رمز التحقق بنجاح",
          otpverifyfailed:"فشل التحقق من رمز التحقق",
          otpverifyexpired:"فشل التحقق من رمز التحقق",
          otpSent: "تم ارسال رمز التحقق بنجاح",
          otpVerified: "تم التحقق من رمز التحقق بنجاح",
          otpFailed: "رمز التحقق غير صحيح",
          otpSendFailed: "فشل ارسال رمز التحقق",
          loginSuccess: "تم تسجيل الدخول بنجاح",
          loginFailed: "فشل تسجيل الدخول",
          userNotActive: "يرجى اكمال التسجيل اولا",
          registrationComplete: "تم اكمال التسجيل بنجاح",
          registrationFailed: "فشل التسجيل",
          passwordMismatch: "كلمة المرور غير متطابقة"
        },
        medicalInfo: {
          title: 'معلومات طبية',
          description: 'نوفر لك مجموعة من المقالات الطبية الموثوقة والشاملة، لتكون مصدراً غنياً بالمعلومات الصحية التي تحتاجها.',
          readMore: 'اقرأ المزيد',
          viewMore: 'اقرأ المزيد',
          article1: {
            title: 'هل يستطيع حامل الثلاسيميا التبرع بالدم؟',
            description: 'يحمي فحص الفوليك الجنين من العيوب الخلقية الخطيرة، ويتوفر في الفيتامينات والأطعمة المدعمة، مثل الخبز...'
          },
          article2: {
            title: 'مخاطر علاج انسداد الأنف عند الرضع بزيت الزيتون',
            description: 'يحمي فحص الفوليك الجنين من العيوب الخلقية الخطيرة، ويتوفر في الفيتامينات والأطعمة المدعمة، مثل الخبز...'
          }
        },
        healthCare: {
          title: 'رعاية صحية',
          description: 'تقدم مجموعة تداوي الطبية خدمات الرعاية المنزلية لضمان توفير الرعاية الصحية المريحة والآمنة للمرضى في منازلهم. تشمل خدماتنا',
          service1: {
            title: 'رعاية مرضى العناية المركزة',
            description: 'فريق متخصص لتقديم الرعاية الطبية للمرضى الذين يحتاجون إلى مراقبة دقيقة وعناية خاصة.'
          },
          service2: {
            title: 'رعاية مرضى العناية المركزة',
            description: 'فريق متخصص لتقديم الرعاية الطبية للمرضى الذين يحتاجون إلى مراقبة دقيقة وعناية خاصة.'
          },
          service3: {
            title: 'رعاية مرضى العناية المركزة',
            description: 'فريق متخصص لتقديم الرعاية الطبية للمرضى الذين يحتاجون إلى مراقبة دقيقة وعناية خاصة.'
          }
        },
        joinUs: {
          title: "انضم إلى شبكتنا الطبية",
          description: "هل أنت متخصص في الرعاية الصحية وتتطلع إلى إحداث فرق؟ انضم إلى شبكتنا المتنامية من مقدمي الخدمات الطبية.",
          subDescription: "نحن نقدم تعويضات تنافسية، وجداول مرنة، وفرصة لتكون جزءاً من منصة رعاية صحية رائدة.",
          button: "انضم إلى فريقنا"
        },
        testimonials: {
          testimonial1: {
            text: "الخدمة في مركز تداوي الطبي كانت استثنائية. كان الطاقم محترفاً ومهتماً، مما جعل تجربتي مريحة ومطمئنة.",
            author: "فاطمة خ."
          },
          clinicImage: "داخل العيادة"
        },
        faq: {
          title: "الأسئلة الشائعة",
          description: "قسم الأسئلة الشائعة لدينا مصمم لتزويدك بإجابات سريعة وشاملة عن الاستفسارات الشائعة.",
          question1: "كيف يمكنني حجز موعد مع طبيب من خلال الموقع؟",
          answer1: "يمكنك بسهولة حجز موعد من خلال زيارة صفحة الأطباء لدينا، واختيار الطبيب المفضل لديك، واختيار الوقت المناسب، وملء نموذج الحجز. ستتلقى رسالة تأكيد عبر البريد الإلكتروني بمجرد جدولة موعدك.",
          question2: "ما هي التخصصات المتوفرة في مركزكم الطبي؟",
          answer2: "نقدم مجموعة واسعة من التخصصات الطبية بما في ذلك الطب الباطني، طب الأطفال، أمراض القلب، الأمراض الجلدية، العظام، وغيرها. قم بزيارة صفحة التخصصات لرؤية القائمة الكاملة.",
          question3: "كيف يمكنني عرض سجلي الطبي والمواعيد السابقة؟",
          answer3: "بعد تسجيل الدخول إلى حسابك، يمكنك الوصول إلى سجلك الطبي، وعرض المواعيد السابقة، وتحميل التقارير الطبية من لوحة تحكم المريض الخاصة بك.",
          question4: "ما هي طرق الدفع التي تقبلونها؟",
          answer4: "نقبل طرق دفع متنوعة تشمل بطاقات الائتمان، بطاقات الخصم، والتغطية التأمينية. يمكن معالجة تفاصيل الدفع بشكل آمن من خلال موقعنا.",
          question5: "هل يمكنني إلغاء أو إعادة جدولة موعدي؟",
          answer5: "نعم، يمكنك إلغاء أو إعادة جدولة موعدك من خلال لوحة تحكم حسابك قبل 24 ساعة من الموعد المحدد. يرجى الرجوع إلى سياسة الإلغاء لمزيد من التفاصيل.",
          error: "تعذر تحميل بيانات الأسئلة الشائعة. يرجى المحاولة مرة أخرى لاحقاً."
        },
        bookingPage: {
          pageTitle: "حجز موعد",
          title: "احجز موعدك",
          searchPlaceholder: "ابحث عن الأطباء...",
          filter: {
            reset: "إعادة ضبط خيارات البحث",
            branch: "اختر الفرع",
            degree: "الدرجة العلمية",
            price: "سعر الكشف",
            type: "النوع"
          }
        },
        common: {
          loading: "جاري التحميل ...",
          error: "حدث خطأ. يرجى المحاولة مرة أخرى.",
        },
        doctorCard: {
          reviews: "تقييم",
          branch: "الفرع",
          degree: "الدرجة العلمية",
          price: "السعر",
          currency: "ريال",
          type: "التخصص",
          nextAvailable: "أقرب موعد",
          bookNow: "احجز الآن",
        },
        bookingWizard: {
          selectDoctor: "اختر الطبيب",
          selectDateTime: "اختر التاريخ والوقت",
          enterDetails: "أدخل التفاصيل",
          payment: "الدفع",
          experience: "{{years}} سنوات من الخبرة",
          consultationFee: "لكل استشارة",
          patientName: "اسم المريض",
          patientNamePlaceholder: "أدخل الاسم الكامل للمريض",
          patientPhone: "رقم الهاتف",
          patientPhonePlaceholder: "أدخل رقم الهاتف",
          paymentOptions: {
            creditCard: "بطاقات الائتمان",
            cash: "كاش في العيادة"
          },
          back: "رجوع",
          next: "اكمل",
          finish: "انهاء",
          calendar: {
            weekDays: {
              friday: "جمعة",
              saturday: "سبت",
              sunday: "أحد",
              monday: "اثنين",
              tuesday: "ثلاثاء",
              wednesday: "اربعاء",
              thursday: "خميس"
            },
            months: {
              september: "سبتمبر"
            }
          }
        }
      },
    },
  },
  lng: savedLanguage,
  fallbackLng: "ar",
  interpolation: {
    escapeValue: false,
  },
});

// Add a language change handler
i18n.on("languageChanged", (lng) => {
  // Save to localStorage
  localStorage.setItem("language", lng);

  // Update HTML dir attribute
  document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";
});

export default i18n;
