import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getTestimonials } from '../../services/testimonialService';
import starIcon from '../../assets/icons/star.png';
import halfStarIcon from '../../assets/icons/half-star.png';
import quoteIcon from '../../assets/icons/QuoteIcon.png';

const Section = styled.section`
  padding: 80px 0;
  background-color: #fff;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 60px;

  @media (max-width: 992px) {
    flex-direction: column;
    text-align: center;
  }
`;

const QuoteIcon = styled.img`
  position: absolute;
  top: 0;
  width: 40px;
  height: auto;
  opacity: 0.1;
  left: ${props => props.isRTL ? 'auto' : '0'};
  right: ${props => props.isRTL ? '0' : 'auto'};
  user-select: none;
  transform: ${props => props.isRTL ? 'scaleX(-1)' : 'none'};
`;

const Content = styled.div`
  flex: 1;
  color: #1B365D;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  position: relative;
  padding: ${props => props.isRTL ? '0 60px 0 0' : '0 0 0 60px'};

  @media (max-width: 992px) {
    padding: 0;
    text-align: center;
  }
`;

const TestimonialText = styled.p`
  font-size: 1.25rem;
  line-height: 1.8;
  margin-bottom: 30px;
  color: #666;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  font-weight: 400;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.isRTL ? 'flex-end' : 'flex-start'};
  gap: 15px;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    justify-content: center;
  }
`;

const AuthorImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  order: ${props => props.isRTL ? '2' : '1'};
  border: 3px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const AuthorInfo = styled.div`
  order: ${props => props.isRTL ? '1' : '2'};
`;

const AuthorName = styled.h4`
  font-size: 1.1rem;
  margin: 0 0 5px 0;
  color: #1B365D;
  font-weight: 600;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const Rating = styled.div`
  margin-top: 5px;
  display: flex;
  gap: 4px;
  justify-content: ${props => props.isRTL ? 'flex-end' : 'flex-start'};

  @media (max-width: 992px) {
    justify-content: center;
  }
`;

const Star = styled.img`
  width: 16px;
  height: 16px;
  opacity: ${props => props.empty ? 0.3 : 1};
`;

const NavigationButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: ${props => props.isRTL ? 'flex-end' : 'flex-start'};
  margin-top: 30px;

  @media (max-width: 992px) {
    justify-content: center;
  }
`;

const NavButton = styled.button`
  background: ${props => props.disabled ? '#eee' : '#1B365D'};
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: ${props => props.disabled ? '0.5' : '1'};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  &:hover {
    background: ${props => props.disabled ? '#eee' : '#C41E3A'};
  }

  svg {
    width: 20px;
    height: 20px;
    transform: ${props => props.isRTL ? 'scaleX(-1)' : 'none'};
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }

  @media (max-width: 992px) {
    margin-top: 40px;
    
    img {
      max-width: 100%;
    }
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(27, 54, 93, 0.1);
  border-radius: 50%;
  border-top-color: #1B365D;
  animation: spin 1s ease-in-out infinite;
  margin: 20px auto;

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const Testimonials = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [currentPage, setCurrentPage] = useState(1);
  const [testimonialData, setTestimonialData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTestimonial = async (page) => {
    try {
      setLoading(true);
      const data = await getTestimonials(page);
      setTestimonialData(data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestimonial(currentPage);
  }, [currentPage]);

  const handlePrevious = () => {
    if (testimonialData?.hasPreviousPage) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleNext = () => {
    if (testimonialData?.hasNextPage) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    // Add full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <Star 
          key={`full-${i}`} 
          src={starIcon} 
          alt="full star"
        />
      );
    }

    // Add half star if needed
    if (hasHalfStar) {
      stars.push(
        <Star 
          key="half" 
          src={halfStarIcon} 
          alt="half star"
        />
      );
    }

    // Add empty stars
    const emptyStars = 5 - Math.ceil(rating);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <Star 
          key={`empty-${i}`} 
          src={starIcon} 
          alt="empty star"
          empty
        />
      );
    }

    return stars;
  };

  if (error) {
    return null; // Hide the section if there's an error
  }

  return (
    <Section>
      <Container>
        <Content isRTL={isRTL}>
          <QuoteIcon src={quoteIcon} alt="" aria-hidden="true" isRTL={isRTL} />
          {loading ? (
            <LoadingSpinner />
          ) : testimonialData?.items[0] && (
            <>
              <TestimonialText isRTL={isRTL}>
                {testimonialData.items[0].post}
              </TestimonialText>
              <Author isRTL={isRTL}>
                <AuthorImage 
                  src={`data:image/jpeg;base64,${testimonialData.items[0].client.image}`}
                  alt={testimonialData.items[0].client.firstName}
                  isRTL={isRTL}
                />
                <AuthorInfo isRTL={isRTL}>
                  <AuthorName isRTL={isRTL}>
                    {testimonialData.items[0].client.firstName} {testimonialData.items[0].client.lastName}
                  </AuthorName>
                  <Rating isRTL={isRTL}>
                    {renderStars(testimonialData.items[0].rateFromFiveStars)}
                  </Rating>
                </AuthorInfo>
              </Author>
              <NavigationButtons isRTL={isRTL}>
                <NavButton 
                  onClick={handlePrevious} 
                  isRTL={isRTL}
                  disabled={!testimonialData.hasPreviousPage}
                >
                  ←
                </NavButton>
                <NavButton 
                  onClick={handleNext} 
                  isRTL={isRTL}
                  disabled={!testimonialData.hasNextPage}
                >
                  →
                </NavButton>
              </NavigationButtons>
            </>
          )}
        </Content>
        <ImageContainer>
          {testimonialData?.items[0] && (
            <img 
              src={`data:image/jpeg;base64,${testimonialData.items[0].image}`}
              alt={t('testimonials.clinicImage')} 
            />
          )}
        </ImageContainer>
      </Container>
    </Section>
  );
};

export default Testimonials;
