import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import banner from '../../assets/register-banner.png';
import { sendOTP, verifyOTP, completeRegistration } from '../../utils/api';
import Notification from '../../components/Notification/Notification';

const RegisterContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #F8F9FA;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  font-family: 'Almarai', sans-serif;
`;

const RegisterForm = styled.form`
  background: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Banner = styled.div`
  background-image: url(${banner});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 150px;
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  text-align: center;
  color: #183457;
  margin-bottom: 2rem;
  font-size: 1.8rem;
`;

const InputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #183457;
  font-weight: 500;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputIcon = styled.div`
  position: absolute;
  ${props => props.isRTL ? 'right' : 'left'}: 12px;
  color: #666;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  padding-${props => props.isRTL ? 'right' : 'left'}: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  font-size: 1rem;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: #183457;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #183457;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  font-family: inherit;

  &:hover {
    background-color: #12263f;
  }
`;

const LoginSection = styled.div`
  text-align: center;
  margin-top: 1.5rem;
`;

const LoginText = styled.span`
  color: #666;
  margin-right: 0.5rem;
`;

const LoginLink = styled.span`
  color: #C4261E;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

const OTPGroup = styled(InputGroup)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const OTPInputGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 2rem 0;
`;

const SingleOTPInput = styled.input`
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  text-align: center;
  font-size: 1.2rem;
  background: #F8F9FA;

  &:focus {
    outline: none;
    border-color: #183457;
    background: white;
  }
`;

const ResendSection = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 1rem;
  color: ${props => props.canResend ? '#C4261E' : '#666'};
`;

const Timer = styled.span`
  color: #666;
`;

const ResendButton = styled.button`
  background: none;
  border: none;
  color: #C4261E;
  cursor: pointer;
  font-family: inherit;
  
  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    color: #666;
    cursor: not-allowed;
    &:hover {
      text-decoration: none;
    }
  }
`;

const Register = () => {
  const [step, setStep] = useState('initial'); // initial, otp, complete
  const [formData, setFormData] = useState({
    identifier: '',
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    aliasName: ''
  });
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(59);
  const [canResend, setCanResend] = useState(false);
  const [notification, setNotification] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isRTL = i18n.language === 'ar';

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const showNotification = (msgId, type) => {
    setNotification({
      message: t(msgId),
      type
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleOTPChange = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOTP = otp.split('');
      newOTP[index] = value;
      setOtp(newOTP.join(''));
      
      if (value && index < 5) {
        const nextInput = e.target.parentElement.children[index + 1];
        nextInput?.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      const prevInput = e.target.parentElement.children[index - 1];
      prevInput?.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (step === 'initial') {
      const result = await sendOTP(formData.phone);
      if (result.success) {
        showNotification(result.msgId, 'success');
        setStep('otp');
        setTimer(59);
        setCanResend(false);
      } else {
        showNotification(result.msgId, 'error');
      }
    } else if (step === 'otp') {
      if (otp.length === 6) {
        const result = await verifyOTP(formData.phone, otp, formData.identifier);
        if (result.success) {
          showNotification(result.msgId, 'success');
          setStep('complete');
        } else {
          showNotification(result.msgId, 'error');
        }
      }
    } else if (step === 'complete') {
      if (formData.password !== formData.confirmPassword) {
        showNotification('msg.passwordmismatch', 'error');
        return;
      }
      
      const registrationData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        phoneNumber: formData.phone,
        aliasName: formData.aliasName,
        idNumberOrResidenceNumber: formData.identifier
      };

      const result = await completeRegistration(registrationData);
      if (result.success) {
        showNotification(result.msgId, 'success');
        setTimeout(() => {
          navigate('/');
        }, 1000);
      } else {
        showNotification(result.msgId, 'error');
      }
    }
  };

  const handleResendOTP = async () => {
    if (canResend) {
      const result = await sendOTP(formData.phone);
      if (result.success) {
        showNotification(result.msgId, 'success');
        setTimer(59);
        setCanResend(false);
      } else {
        showNotification(result.msgId, 'error');
      }
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const renderInitialForm = () => (
    <>
      <InputGroup isRTL={isRTL}>
        <Label>{t('register.identifier')}</Label>
        <InputWrapper isRTL={isRTL}>
          <InputIcon isRTL={isRTL}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 20V19C4 16.2386 6.23858 14 9 14H15C17.7614 14 20 16.2386 20 19V20M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </InputIcon>
          <Input
            type="text"
            name="identifier"
            value={formData.identifier}
            onChange={handleChange}
            placeholder={t('register.identifierPlaceholder')}
            required
            isRTL={isRTL}
          />
        </InputWrapper>
      </InputGroup>
      <InputGroup isRTL={isRTL}>
        <Label>{t('register.phone')}</Label>
        <InputWrapper isRTL={isRTL}>
          <InputIcon isRTL={isRTL}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 5C3 3.89543 3.89543 3 5 3H8.27924C8.70967 3 9.09181 3.27543 9.22792 3.68377L10.7257 8.17721C10.8831 8.64932 10.6694 9.16531 10.2243 9.38787L7.96701 10.5165C9.06925 12.9612 11.0388 14.9308 13.4835 16.033L14.6121 13.7757C14.8347 13.3306 15.3507 13.1169 15.8228 13.2743L20.3162 14.7721C20.7246 14.9082 21 15.2903 21 15.7208V19C21 20.1046 20.1046 21 19 21H18C9.71573 21 3 14.2843 3 6V5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </InputIcon>
          <Input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder={t('register.phonePlaceholder')}
            required
            isRTL={isRTL}
          />
        </InputWrapper>
      </InputGroup>
    </>
  );

  const renderOTPForm = () => (
    <OTPGroup>
      <Label>{t('register.otpMessage')} {formData.phone}</Label>
      <OTPInputGroup>
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <SingleOTPInput
            key={index}
            type="text"
            maxLength="1"
            value={otp[index] || ''}
            onChange={(e) => handleOTPChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            autoFocus={index === 0}
          />
        ))}
      </OTPInputGroup>
      <ResendSection canResend={canResend}>
        <span>{t('register.resendCode')}</span>
        {!canResend && <Timer>{String(timer).padStart(2, '0')}:00</Timer>}
        {canResend && (
          <ResendButton type="button" onClick={handleResendOTP}>
            {t('register.resendOTP')}
          </ResendButton>
        )}
      </ResendSection>
    </OTPGroup>
  );

  const renderCompleteForm = () => (
    <>
      <InputGroup>
        <Label>{t('register.firstName')}</Label>
        <Input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder={t('register.firstNamePlaceholder')}
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>{t('register.lastName')}</Label>
        <Input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder={t('register.lastNamePlaceholder')}
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>{t('register.email')}</Label>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder={t('register.emailPlaceholder')}
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>{t('register.password')}</Label>
        <Input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder={t('register.passwordPlaceholder')}
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>{t('register.confirmPassword')}</Label>
        <Input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          placeholder={t('register.confirmPasswordPlaceholder')}
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>{t('register.aliasName')}</Label>
        <Input
          type="text"
          name="aliasName"
          value={formData.aliasName}
          onChange={handleChange}
          placeholder={t('register.aliasNamePlaceholder')}
        />
      </InputGroup>
    </>
  );

  return (
    <RegisterContainer isRTL={isRTL}>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
        />
      )}
      <RegisterForm onSubmit={handleSubmit}>
        <Banner />
        <Title>{t('register.title')}</Title>
        {step === 'initial' && renderInitialForm()}
        {step === 'otp' && renderOTPForm()}
        {step === 'complete' && renderCompleteForm()}
        <Button type="submit">
          {step === 'initial' && t('register.submit')}
          {step === 'otp' && t('register.verifyOTP')}
          {step === 'complete' && t('register.completeRegistration')}
        </Button>
        <LoginSection>
          <LoginText>{t('register.haveAccount')}</LoginText>
          <LoginLink onClick={handleLogin}>{t('register.login')}</LoginLink>
        </LoginSection>
      </RegisterForm>
    </RegisterContainer>
  );
};

export default Register;
