import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ChatButton from './components/ChatButton/ChatButton';
import CookieConsent from './components/CookieConsent/CookieConsent';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import MainPage from './pages/MainPage/MainPage';
import BookingPage from './pages/BookingPage/BookingPage';
import './i18n';

const MainContent = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 120px; /* Combined height of Header and InnerHeader */

  @media (max-width: 1180px) {
    padding-top: 160px; /* Increased padding for mobile view */
  }
`;


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <MainContent>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/booking" element={<BookingPage />} />
          </Routes>
        </MainContent>
        <Footer />
        <ChatButton />
        <CookieConsent />
      </div>
    </Router>
  );
}

export default App;