import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BiRefresh } from "react-icons/bi";

const FilterContainer = styled.div`
  padding: 0 2.5rem;
  margin-bottom: 2rem;
  direction: ${props => props.isRTL ? "rtl" : "ltr"};
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: ${props => props.isRTL ? 'flex-start' : 'flex-end'};
  align-items: center;
  margin-bottom: 2rem;
`;

const ResetButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-family: "Almarai", sans-serif;
  font-size: 0.9rem;
  direction: ${(props) => (props.isRTL ? "rtl" : "ltr")};
  
  &:hover {
    color: #C4261E;
  }

  svg {
    font-size: 1.2rem;
    ${props => props.isRTL ? 'margin-left: 0.5rem;' : 'margin-right: 0.5rem;'}
  }
`;

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-family: "Almarai", sans-serif;
  font-size: 0.9rem;
  color: #333;
  background-color: white;
  cursor: pointer;
  direction: ${props => props.isRTL ? "rtl" : "ltr"};

  &:focus {
    outline: none;
    border-color: #C4261E;
  }

  &:hover {
    border-color: #C4261E;
  }
`;

const BookingFilter = ({ filters, filterOptions, onFilterChange, isRTL }) => {
  const { t } = useTranslation();

  const handleFilterChange = (type, value) => {
    onFilterChange(type, value);
  };

  return (
    <FilterContainer isRTL={isRTL}>
      <FilterHeader isRTL={isRTL}>
        <ResetButton onClick={() => onFilterChange('reset')} isRTL={isRTL}>
          {isRTL ? (
            <>
              {t("bookingPage.filter.reset")}
              <BiRefresh />
            </>
          ) : (
            <>
              <BiRefresh />
              {t("bookingPage.filter.reset")}
            </>
          )}
        </ResetButton>
      </FilterHeader>
      <FiltersGrid>
        <FilterSelect
          value={filters.branch}
          onChange={(e) => handleFilterChange('branch', e.target.value)}
          isRTL={isRTL}
        >
          <option value="">{t("bookingPage.filter.branch")}</option>
          {filterOptions?.branches?.map((branch) => (
            <option key={branch} value={branch}>{branch}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.degree}
          onChange={(e) => handleFilterChange('degree', e.target.value)}
          isRTL={isRTL}
        >
          <option value="">{t("bookingPage.filter.degree")}</option>
          {filterOptions?.degrees?.map((degree) => (
            <option key={degree} value={degree}>{degree}</option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.price}
          onChange={(e) => handleFilterChange('price', e.target.value)}
          isRTL={isRTL}
        >
          <option value="">{t("bookingPage.filter.price")}</option>
          {filterOptions?.priceRanges?.map((range) => (
            <option key={`${range.min}-${range.max}`} value={`${range.min}-${range.max}`}>
              {range.label}
            </option>
          ))}
        </FilterSelect>

        <FilterSelect
          value={filters.type}
          onChange={(e) => handleFilterChange('type', e.target.value)}
          isRTL={isRTL}
        >
          <option value="">{t("bookingPage.filter.type")}</option>
          {filterOptions?.types?.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </FilterSelect>
      </FiltersGrid>
    </FilterContainer>
  );
};

export default BookingFilter;
