import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import joinUsFrame from '../../assets/frame-joinus.png';
import joinUsDoctor from '../../assets/joinus-doctor.png';

const Section = styled.section`
  padding: 60px 0;
  background: transparent;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 992px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FrameImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 500px;
  display: block;
  margin: 0 auto;
  
  @media (max-width: 992px) {
    max-width: 80%;
  }
`;

const DoctorImage = styled.img`
  position: absolute;
  width: 90%;
  height: auto;
  max-width: 450px;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  @media (max-width: 992px) {
    max-width: 70%;
  }
`;

const Content = styled.div`
  flex: 1;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  
  @media (max-width: 992px) {
    order: 2;
    text-align: center;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #1B365D;
  font-weight: bold;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  text-align: ${props => props.isRTL ? 'right' : 'left'};

  @media (max-width: 992px) {
    text-align: center;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  margin-bottom: 15px;
  line-height: 1.6;
  color: #333;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  text-align: ${props => props.isRTL ? 'right' : 'left'};

  @media (max-width: 992px) {
    text-align: center;
  }
`;

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  padding: 12px 24px;
  background-color: #fff;
  color: #C41E3A;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: all 0.3s ease;
  gap: 8px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateX(${props => props.isRTL ? '-5px' : '5px'});
  }
`;

const Arrow = styled.span`
  transform: scaleX(${props => props.isRTL ? -1 : 1});
`;

const JoinUs = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  return (
    <Section>
      <Container>
        <ImageContainer>
          <FrameImage src={joinUsFrame} alt="" aria-hidden="true" />
          <DoctorImage src={joinUsDoctor} alt={t('joinUs.title')} />
        </ImageContainer>
        <Content isRTL={isRTL}>
          <Title isRTL={isRTL}>{t('joinUs.title')}</Title>
          <Description isRTL={isRTL}>{t('joinUs.description')}</Description>
          <Description isRTL={isRTL}>{t('joinUs.subDescription')}</Description>
          <Button to="/careers" isRTL={isRTL}>
            {t('joinUs.button')}
            <Arrow isRTL={isRTL}>→</Arrow>
          </Button>
        </Content>
      </Container>
    </Section>
  );
};

export default JoinUs;
