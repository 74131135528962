import React from 'react';
import styled from 'styled-components';
import tadawiLab from '../../assets/partners/tadawi-lab.png';
import globalEye from '../../assets/partners/global-eye.png';
import happyKids from '../../assets/partners/happy-kids.png';
import noc from '../../assets/partners/noc.png';
import janen from '../../assets/partners/janen.png';
import tadawiDent from '../../assets/partners/tadawi-dent.png';

const PartnersContainer = styled.section`
  width: 100%;
  background: #F6F9FC;
  padding: 2rem 0;
  margin-top: -2rem;
  position: relative;
  z-index: 1;
`;

const PartnersWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 4rem;

  @media (max-width: 968px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 2rem;
  }
`;

const PartnerLogo = styled.img`
  height: 60px;
  object-fit: contain;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 968px) {
    height: 50px;
  }
`;

const Partners = () => {
  const partners = [
    { src: tadawiDent, alt: 'Tadawi Dent' },
    { src: janen, alt: 'Janen' },
    { src: noc, alt: 'NOC' },
    { src: happyKids, alt: 'Happy Kids' },
    { src: globalEye, alt: 'Global Eye Care' },
    { src: tadawiLab, alt: 'Tadawi Laboratory' }
  ];

  return (
    <PartnersContainer>
      <PartnersWrapper>
        {partners.map((partner, index) => (
          <PartnerLogo
            key={index}
            src={partner.src}
            alt={partner.alt}
          />
        ))}
      </PartnersWrapper>
    </PartnersContainer>
  );
};

export default Partners;
