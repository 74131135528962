import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import visionIcon from '../../assets/icons/our_vision.png';
import goalsIcon from '../../assets/icons/our_goals.png';
import messageIcon from '../../assets/icons/our_message.png';

const AboutSection = styled.section`
  padding: 4rem 0;
  text-align: center;
  background: #fff;
`;

const Title = styled.h2`
  color: #C4261E;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
`;

const Description = styled.p`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.8;
  max-width: 1000px;
  margin: 0 auto 3rem;
  padding: 0 2rem;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  font-family: 'Almarai', sans-serif;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 968px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  flex: 1;
  max-width: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F2F4F5;
  padding: 2rem;
  border-radius: 16px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const IconContainer = styled.div`
  width: 80px;
  height: 80px;
  background: #183457;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const CardTitle = styled.h3`
  color: #183457;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
`;

const ReadMore = styled.button`
  background: #183457;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 3rem auto 0;
  transition: transform 0.3s ease;
  font-family: 'Almarai', sans-serif;

  &:hover {
    transform: translateX(${props => props.isRTL ? '5px' : '-5px'});
  }

  svg {
    width: 20px;
    height: 20px;
    transform: ${props => props.isRTL ? 'rotate(0deg)' : 'rotate(180deg)'};
  }
`;

const About = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  const cards = [
    {
      icon: messageIcon,
      title: t('mainPage.about.mission.title'),
      alt: "Mission Icon"
    },
    {
      icon: goalsIcon,
      title: t('mainPage.about.goals.title'),
      alt: "Goals Icon"
    },
    {
      icon: visionIcon,
      title: t('mainPage.about.vision.title'),
      alt: "Vision Icon"
    }
  ];

  // Reverse the order for RTL languages
  const orderedCards = isRTL ? cards : [...cards].reverse();

  return (
    <AboutSection>
      <Title>{t('mainPage.about.title')}</Title>
      <Description isRTL={isRTL}>{t('mainPage.about.description')}</Description>
      <CardsContainer>
        {orderedCards.map((card, index) => (
          <Card key={index}>
            <IconContainer>
              <img src={card.icon} alt={card.alt} />
            </IconContainer>
            <CardTitle>{card.title}</CardTitle>
          </Card>
        ))}
      </CardsContainer>
      <ReadMore isRTL={isRTL}>
        <span>{t('mainPage.about.readMore')}</span>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </ReadMore>
    </AboutSection>
  );
};

export default About;
