import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

const OffersSection = styled.section`
  margin: 4rem 0 0 0;
  padding: 4rem 0;
  background: #fff;
  overflow: hidden;
  background: #F8F9FA;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  background: #F6F9FC;
`;

const Title = styled.h2`
  color: #C4261E;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'Almarai', sans-serif;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 700px;
  overflow: hidden;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
`;

const SlideWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  height: 100%;
  transform: translateX(${props => props.currentSlide * -100}%);
`;

const Slide = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  gap: 4rem;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  align-items: center;
`;

const OfferImage = styled.div`
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  max-height: 900px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 1.5rem;

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    object-fit: contain;
    border-radius: 8px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }
  }
`;

const OfferContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  padding: 2rem;
  max-width: 40%;
`;

const OfferTitle = styled.h3`
  color: #C4261E;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-family: 'Almarai', sans-serif;
`;

const OfferDescription = styled.div`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  font-family: 'Almarai', sans-serif;
`;

const ReadMoreButton = styled.button`
  background: #183457;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 3rem auto 0;
  transition: transform 0.3s ease;
  font-family: 'Almarai', sans-serif;

  &:hover {
    transform: translateX(${props => props.isRTL ? '5px' : '-5px'});
  }

  svg {
    width: 20px;
    height: 20px;
    transform: ${props => props.isRTL ? 'rotate(0deg)' : 'rotate(180deg)'};
  }
`;
const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
`;

const Dot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.active ? '#183457' : '#ddd'};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.active ? '#183457' : '#bbb'};
  }
`;

const Offers = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const [offers, setOffers] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchNextOffer = async () => {
    if (!hasMore) return;
    
    try {
      console.log('Fetching next offer, page:', nextPage);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Store/offers?pageNumber=${nextPage}&pageSize=1`
      );
      
      console.log('Response:', response.data);
      
      if (response.data && response.data.items && response.data.items.length > 0) {
        const newOffer = response.data.items[0];
        console.log('Adding new offer:', newOffer);
        
        setOffers(prev => {
          const updated = [...prev, newOffer];
          console.log('Updated offers array:', updated);
          return updated;
        });
        
        setNextPage(prev => prev + 1);
        // Check if there are more pages based on total count
        setHasMore(response.data.totalCount > nextPage);
      } else {
        console.log('No more offers available');
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching next offer:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Initial load - fetch first two offers
  useEffect(() => {
    const loadInitialOffers = async () => {
      await fetchNextOffer();
      if (hasMore) {
        await fetchNextOffer();
      }
    };
    loadInitialOffers();
  }, []);

  // Auto-slide and fetch next
  useEffect(() => {
    if (!loading && offers.length > 0) {
      const slideInterval = setInterval(() => {
        if (!isPaused) {
          setCurrentSlide((current) => {
            console.log('Current slide:', current, 'Total offers:', offers.length);
            const nextSlide = current === offers.length - 1 ? 0 : current + 1;
            
            // If we're on the last slide, fetch next offer
            if (current === offers.length - 2 && hasMore) {
              console.log('Near end, fetching next offer');
              fetchNextOffer();
            }
            
            return nextSlide;
          });
        }
      }, 5000);

      return () => clearInterval(slideInterval);
    }
  }, [loading, offers.length, isPaused, hasMore]);

  const handleDotClick = (index) => {
    console.log('Dot clicked:', index);
    setCurrentSlide(index);
    
    // If clicking the second-to-last dot, fetch next offer
    if (index === offers.length - 2 && hasMore) {
      console.log('Clicked near last dot, fetching next offer');
      fetchNextOffer();
    }
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  // Debug render
  useEffect(() => {
    console.log('Current state:', {
      offersCount: offers.length,
      currentSlide,
      hasMore,
      nextPage,
      loading
    });
  }, [offers, currentSlide, hasMore, nextPage, loading]);

  if (loading && offers.length === 0) {
    return (
      <OffersSection>
        <Container>
          <Title>{t('mainPage.offers.title')}</Title>
          <div style={{ textAlign: 'center', padding: '2rem' }}>Loading...</div>
        </Container>
      </OffersSection>
    );
  }

  if (error && offers.length === 0) {
    return (
      <OffersSection>
        <Container>
          <Title>{t('mainPage.offers.title')}</Title>
          <div style={{ textAlign: 'center', padding: '2rem', color: '#C4261E' }}>
            {error}
          </div>
        </Container>
      </OffersSection>
    );
  }

  if (!offers || offers.length === 0) {
    return (
      <OffersSection>
        <Container>
          <Title>{t('mainPage.offers.title')}</Title>
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            {t('mainPage.offers.noOffers')}
          </div>
        </Container>
      </OffersSection>
    );
  }

  return (
    <OffersSection>
      <Container>
        <Title>{t('mainPage.offers.title')}</Title>
        <SliderContainer 
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <SlideWrapper currentSlide={currentSlide}>
            {offers.map((offer, index) => (
              <Slide key={offer.id} isRTL={isRTL}>
                
                <OfferContent isRTL={isRTL}>
                  <OfferTitle>{offer.title}</OfferTitle>
                  <OfferDescription>
                    <ReactMarkdown>{offer.description}</ReactMarkdown>
                  </OfferDescription>
                  <ReadMoreButton isRTL={isRTL}>
        <span>{t('mainPage.offers.readMore')}</span>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </ReadMoreButton>
                </OfferContent>
                <OfferImage>
                  <img src={`data:image/png;base64,${offer.image}`} alt={offer.title} loading="lazy"  />
                </OfferImage>
              </Slide>
            ))}
          </SlideWrapper>
        </SliderContainer>
        <DotsContainer>
          {offers.map((_, index) => (
            <Dot
              key={index}
              active={currentSlide === index}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </DotsContainer>
      </Container>
    </OffersSection>
  );
};

export default Offers;
