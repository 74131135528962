import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor to add auth token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const getDoctors = async ({ page, limit, search, branch, degree, price, type }) => {
  try {
    const params = {
      search,
      pageNumber: page,
      pageSize: limit,
      branch,
      degree,
      price,
      type
    };

    // Remove undefined parameters
    Object.keys(params).forEach(key => 
      params[key] === undefined && delete params[key]
    );

    const response = await api.get('/appointments/doctors', { params });
    return {
      doctors: response.data.items,
      pagination: {
        currentPage: response.data.pageNumber,
        totalPages: response.data.totalPages,
        totalDoctors: response.data.totalCount,
        limit: response.data.pageSize,
        hasNextPage: response.data.hasNextPage,
        hasPreviousPage: response.data.hasPreviousPage
      }
    };
  } catch (error) {
    throw error;
  }
};

export const getFilters = async () => {
  try {
    const response = await api.get('/appointments/doctors/filters');
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await api.get('/users/me');
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch user profile');
  }
};

export default api;
