import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiUser, FiLogOut, FiCalendar, FiChevronDown, FiMenu, FiX, FiBell, FiShoppingBag } from "react-icons/fi";
import { getUserProfile } from "../../services/api";
import logo from "../../assets/logo.svg";

const HeaderContainer = styled.header`
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled(Link)`
  img {
    height: 40px;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const LoginButton = styled(Link)`
  padding: 0.5rem 1.5rem;
  background-color: #C4261E;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  font-family: "Almarai", sans-serif;
  transition: background-color 0.2s;

  &:hover {
    background-color: #A01E17;
  }
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 8px;
  transition: background-color 0.2s;
  position: relative;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const UserAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #C4261E;
  order: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UserName = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  font-family: "Almarai", sans-serif;
  order: ${props => props.isRTL ? '3' : '1'};
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
  color: #1B365D;
  
  svg {
    font-size: 1.5rem;
  }

  &:hover {
    color: #C4261E;
  }
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background: #C4261E;
  color: white;
  font-size: 0.75rem;
  padding: 0.125rem 0.375rem;
  border-radius: 10px;
  font-family: "Almarai", sans-serif;
`;

const UserMenuDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: ${props => props.isRTL ? 'auto' : '0'};
  left: ${props => props.isRTL ? '0' : 'auto'};
  margin-top: 0.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  z-index: 1000;
  overflow: hidden;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const MenuItem = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  background: none;
  color: #333;
  font-family: "Almarai", sans-serif;
  cursor: pointer;
  text-align: ${props => props.isRTL ? 'right' : 'left'};

  &:hover {
    background-color: #f5f5f5;
    color: #C4261E;
  }

  svg {
    font-size: 1.2rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const InnerHeaderContainer = styled.div`
  padding: 1rem 0;
  background-color: #fff;
  border-bottom: 1px solid #E8E8E8;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  font-family: 'Almarai', sans-serif;
`;

const Content = styled.div`
  max-width: 97%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoInner = styled.div`
  cursor: pointer;
  img {
    height: 50px;
    width: auto;
    @media (max-width: 768px) {
      height: 40px;
    }
  }
`;

const NavInner = styled.nav`
  display: flex;
  gap: 2.5rem;
  align-items: center;

  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    ${props => props.isRTL ? 'left: 0;' : 'right: 0;'}
    height: 100vh;
    width: 80%;
    max-width: 400px;
    background: white;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5rem 2rem;
    z-index: 1000;
    box-shadow: ${props => props.isRTL ? '2px 0 10px rgba(0, 0, 0, 0.1)' : '-2px 0 10px rgba(0, 0, 0, 0.1)'};
    transition: transform 0.3s ease-in-out;
    transform: ${({ isOpen, isRTL }) => 
      isOpen ? 'translateX(0)' : 
      isRTL ? 'translateX(-100%)' : 'translateX(100%)'
    };
  }
`;

const NavItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const NavLink = styled.a`
  position: relative;
  color: ${props => props.isActive ? '#C41E3A' : '#1B365D'};
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    color: #C41E3A;
  }

  svg {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
    transform: ${props => props.isOpen ? 'rotate(180deg)' : 'none'};
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  ${props => props.isRTL ? 'right: -20px' : 'left: -20px'};
  background: #fff;
  min-width: 600px;
  border-radius: 20px;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.08);
  opacity: ${props => props.isOpen ? '1' : '0'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transform: translateY(${props => props.isOpen ? '0' : '-10px'});
  transition: all 0.3s ease;
  padding: 20px;
  z-index: 1000;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    ${props => props.isRTL ? 'right: 30px' : 'left: 30px'};
    width: 16px;
    height: 16px;
    background: #fff;
    transform: rotate(45deg);
    box-shadow: -3px -3px 5px rgba(0, 0, 0, 0.04);
  }
`;

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const DropdownRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

const DropdownColumn = styled.div`
  background: #F8F9FA;
  padding: 20px;
  border-radius: 12px;
  transition: all 0.3s ease;

  &:hover {
    background: #F1F3F5;
  }
`;

const SectionTitle = styled.h3`
  color: #1B365D;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
`;

const SectionImage = styled.img`
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
`;

const SectionDescription = styled.p`
  color: #6C757D;
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.5;
`;

const DropdownLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  color: #1B365D;
  text-decoration: none;
  transition: all 0.3s ease;
  border-radius: 12px;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  font-weight: 500;

  &:hover {
    color: #C41E3A;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-${props => props.isRTL ? 'left' : 'right'}: 12px;
    color: #6C757D;
    transition: color 0.3s ease;
  }

  &:hover svg {
    color: #C41E3A;
  }
`;

const LoginButtonInner = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #183457;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  font-family: 'Almarai', sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    margin: ${props => props.isRTL ? '0 0 0 8px' : '0 8px 0 0'};
  }

  &:hover {
    background-color: #122843;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 1024px) {
    padding: 0.6rem 1.5rem;
    font-size: 14px;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: #183457;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
  margin: ${props => props.isRTL ? '0 1rem 0 0' : '0 0 0 1rem'};
  font-family: 'Almarai', sans-serif;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Overlay = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
`;

const MobileHeader = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 2rem;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 2rem;
    font-family: 'Almarai', sans-serif;
  }
`;

const ChevronIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const AboutIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 16V12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 8H12.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const TeamIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const HistoryIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 8V12L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.05 11C3.27151 8.80643 4.30789 6.77578 5.95057 5.27573C7.59325 3.77568 9.7392 2.90878 12 2.83C14.2608 2.90878 16.4068 3.77568 18.0494 5.27573C19.6921 6.77578 20.7285 8.80643 20.95 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.95 13C20.7285 15.1936 19.6921 17.2242 18.0494 18.7243C16.4068 20.2243 14.2608 21.0912 12 21.17C9.7392 21.0912 7.59325 20.2243 5.95057 18.7243C4.30789 17.2242 3.27151 15.1936 3.05 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const UserImageContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #C4261E;
`;

const UserMenu = styled.div`
  position: absolute;
  top: 100%;
  right: ${props => props.isRTL ? 'auto' : '0'};
  left: ${props => props.isRTL ? '0' : 'auto'};
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  margin-top: 0.5rem;
  min-width: 200px;
  z-index: 1000;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const UserMenuItem = styled.div`
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #1B365D;
  transition: background-color 0.2s;
  font-family: "Almarai", sans-serif;

  &:hover {
    background-color: #f5f5f5;
  }

  svg {
    font-size: 1.2rem;
  }
`;

const UserInfo = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #eee;
  
  h4 {
    margin: 0;
    color: #1B365D;
    font-size: 1rem;
    font-family: "Almarai", sans-serif;
  }
  
  p {
    margin: 0.25rem 0 0;
    color: #666;
    font-size: 0.875rem;
    font-family: "Almarai", sans-serif;
  }
`;

const InnerHeader = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const checkAuthAndFetchProfile = async () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const isAuthenticated = !!(token && userId);
      
      setIsLoggedIn(isAuthenticated);
      
      if (isAuthenticated) {
        try {
          const profile = await getUserProfile();
          if (profile) {
            setUserProfile(profile);
          } else {
            throw new Error('No profile data received');
          }
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
          // Only clear auth if it's an authentication error
          if (error.response?.status === 401 || error.response?.status === 403) {
            setIsLoggedIn(false);
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('role');
            localStorage.removeItem('refreshToken');
          }
        }
      } else {
        setUserProfile(null);
      }
    };

    checkAuthAndFetchProfile();

    // Set up event listener for auth changes
    window.addEventListener('storage', (e) => {
      if (e.key === 'token' || e.key === 'userId') {
        checkAuthAndFetchProfile();
      }
    });

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('storage', checkAuthAndFetchProfile);
    };
  }, []);

  const fetchUserProfile = async () => {
    try {
      const profile = await getUserProfile();
      setUserProfile(profile);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      setIsLoggedIn(false);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('role');
      localStorage.removeItem('refreshToken');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('refreshToken');
    setIsLoggedIn(false);
    setUserProfile(null);
    setMenuOpen(false);
    navigate('/');
  };

  const handleAppointments = () => {
    navigate('/appointments');
    setMenuOpen(false);
  };

  const handleProfile = () => {
    navigate('/profile');
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const aboutSections = [
    {
      title: t('InnerHeader.about'),
      description: t('InnerHeader.aboutDescription'),
      image: '/images/about.jpg',
      links: [
        {
          icon: <AboutIcon />,
          text: t('InnerHeader.mission'),
          to: '/about/mission'
        },
        {
          icon: <TeamIcon />,
          text: t('InnerHeader.vision'),
          to: '/about/vision'
        }
      ]
    },
    {
      title: t('InnerHeader.team'),
      description: t('InnerHeader.teamDescription'),
      image: '/images/team.jpg',
      links: [
        {
          icon: <TeamIcon />,
          text: t('InnerHeader.doctors'),
          to: '/team/doctors'
        },
        {
          icon: <TeamIcon />,
          text: t('InnerHeader.staff'),
          to: '/team/staff'
        }
      ]
    },
    {
      title: t('InnerHeader.history'),
      description: t('InnerHeader.historyDescription'),
      image: '/images/history.jpg',
      links: [
        {
          icon: <HistoryIcon />,
          text: t('InnerHeader.timeline'),
          to: '/history/timeline'
        },
        {
          icon: <HistoryIcon />,
          text: t('InnerHeader.achievements'),
          to: '/history/achievements'
        }
      ]
    },
    {
      title: t('InnerHeader.values'),
      description: t('InnerHeader.valuesDescription'),
      image: '/images/values.jpg',
      links: [
        {
          icon: <AboutIcon />,
          text: t('InnerHeader.coreValues'),
          to: '/values/core'
        },
        {
          icon: <AboutIcon />,
          text: t('InnerHeader.commitment'),
          to: '/values/commitment'
        }
      ]
    }
  ];

  return (
    <InnerHeaderContainer isRTL={isRTL}>
      <Content>
        <LogoInner onClick={() => navigate('/')}>
          <img src={logo} alt="تداوي" />
        </LogoInner>
        <NavInner isOpen={isMenuOpen} isRTL={isRTL}>
          <MobileHeader>
            <LogoInner>
              <img src={logo} alt="تداوي" />
            </LogoInner>
            <MenuButton onClick={toggleMenu} isRTL={isRTL}>
              <FiX />
            </MenuButton>
          </MobileHeader>
          <NavItem 
            onMouseEnter={() => setIsAboutOpen(true)}
            onMouseLeave={() => setIsAboutOpen(false)}
          >
            <NavLink 
              href="#" 
              isRTL={isRTL}
              isOpen={isAboutOpen}
            >
              {t('InnerHeader.about-group')}
              <ChevronIcon />
            </NavLink>
            <Dropdown isOpen={isAboutOpen} isRTL={isRTL}>
              <DropdownContent>
                <DropdownRow>
                  <DropdownColumn>
                    <SectionTitle>{aboutSections[0].title}</SectionTitle>
                    <SectionDescription>{aboutSections[0].description}</SectionDescription>
                    {aboutSections[0].links.map((link, linkIndex) => (
                      <DropdownLink
                        key={linkIndex}
                        href={link.to}
                        isRTL={isRTL}
                      >
                        {link.icon}
                        {link.text}
                      </DropdownLink>
                    ))}
                  </DropdownColumn>
                  <DropdownColumn>
                    <SectionTitle>{aboutSections[1].title}</SectionTitle>
                    <SectionDescription>{aboutSections[1].description}</SectionDescription>
                    {aboutSections[1].links.map((link, linkIndex) => (
                      <DropdownLink
                        key={linkIndex}
                        href={link.to}
                        isRTL={isRTL}
                      >
                        {link.icon}
                        {link.text}
                      </DropdownLink>
                    ))}
                  </DropdownColumn>
                </DropdownRow>
                <DropdownRow>
                  <DropdownColumn>
                    <SectionTitle>{aboutSections[2].title}</SectionTitle>
                    <SectionDescription>{aboutSections[2].description}</SectionDescription>
                    {aboutSections[2].links.map((link, linkIndex) => (
                      <DropdownLink
                        key={linkIndex}
                        href={link.to}
                        isRTL={isRTL}
                      >
                        {link.icon}
                        {link.text}
                      </DropdownLink>
                    ))}
                  </DropdownColumn>
                  <DropdownColumn>
                    <SectionTitle>{aboutSections[3].title}</SectionTitle>
                    <SectionDescription>{aboutSections[3].description}</SectionDescription>
                    {aboutSections[3].links.map((link, linkIndex) => (
                      <DropdownLink
                        key={linkIndex}
                        href={link.to}
                        isRTL={isRTL}
                      >
                        {link.icon}
                        {link.text}
                      </DropdownLink>
                    ))}
                  </DropdownColumn>
                </DropdownRow>
              </DropdownContent>
            </Dropdown>
          </NavItem>
          <NavLink href="#" isRTL={isRTL}>
            {t('InnerHeader.medical-services')}
            <FiChevronDown />
          </NavLink>
          <NavLink href="/booking" isRTL={isRTL}>{t('InnerHeader.book-appointment')}</NavLink>
          <NavLink href="#" isRTL={isRTL}>{t('InnerHeader.store')}</NavLink>
          <NavLink href="#" isRTL={isRTL}>{t('InnerHeader.offers')}</NavLink>
          <NavLink href="#" isRTL={isRTL}>{t('InnerHeader.medical-info')}</NavLink>
        </NavInner>
        {isLoggedIn && userProfile ? (
          <UserSection>
            <IconButton>
              <FiBell />
              <NotificationBadge>3</NotificationBadge>
            </IconButton>
            <div ref={menuRef}>
              <UserImageContainer onClick={() => setMenuOpen(!menuOpen)}>
                <UserImage
                  src={userProfile?.image ? `data:image/jpeg;base64,${userProfile.image}` : '/images/default-avatar.jpg'}
                  alt={userProfile?.fullName || t('header.userProfile')}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = '/images/default-avatar.jpg';
                  }}
                />
              </UserImageContainer>
              <UserMenu isOpen={menuOpen} isRTL={isRTL}>
                <UserInfo>
                  <h4>{userProfile?.fullName || t('header.user')}</h4>
                  <p>{userProfile?.email || userProfile?.phone || ''}</p>
                </UserInfo>
                <UserMenuItem onClick={handleProfile}>
                  <FiUser />
                  {t('header.profile')}
                </UserMenuItem>
                <UserMenuItem onClick={handleAppointments}>
                  <FiCalendar />
                  {t('header.appointments')}
                </UserMenuItem>
                <UserMenuItem onClick={handleLogout}>
                  <FiLogOut />
                  {t('header.logout')}
                </UserMenuItem>
              </UserMenu>
            </div>
          </UserSection>
        ) : (
          <LoginButton to="/login">
            {t('header.login')}
          </LoginButton>
        )}
        <MenuButton onClick={toggleMenu} isRTL={isRTL}>
          {isMenuOpen ? <FiX /> : <FiMenu />}
        </MenuButton>
      </Content>
      <Overlay isOpen={isMenuOpen} onClick={toggleMenu} />
    </InnerHeaderContainer>
  );
};

export default InnerHeader;
