import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getTestimonials = async (pageNumber = 1, pageSize = 1) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/testimonials`, {
      params: {
        pageNumber,
        pageSize
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching testimonials:', error);
    throw error;
  }
};
