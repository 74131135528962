import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight, FiX, FiMapPin, FiClock, FiUser, FiPhone, FiCreditCard, FiDollarSign } from 'react-icons/fi';
import { AiFillStar } from 'react-icons/ai';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const WizardContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  ${props => props.isRTL ? 'left' : 'right'}: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    color: #C4261E;
  }

  svg {
    font-size: 1.5rem;
  }
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
  padding: 0 2rem;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50px;
    right: 50px;
    height: 2px;
    background-color: #ddd;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 2;
  flex: 1;
`;

const StepNumber = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#C4261E' : props.completed ? '#28a745' : '#ddd'};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: "Almarai", sans-serif;
`;

const StepLabel = styled.span`
  color: ${props => props.active ? '#C4261E' : props.completed ? '#28a745' : '#666'};
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Almarai", sans-serif;
  white-space: nowrap;
`;

const CalendarContainer = styled.div`
  margin-bottom: 2rem;
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 1rem;
`;

const MonthTitle = styled.h3`
  margin: 0;
  font-family: "Almarai", sans-serif;
  color: #333;
`;

const NavigationButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    color: #C4261E;
  }

  svg {
    font-size: 1.5rem;
  }
`;

const WeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  margin-bottom: 0.5rem;
  font-family: "Almarai", sans-serif;
  font-weight: 500;
  color: #666;
`;

const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
`;

const DayCell = styled.button`
  aspect-ratio: 1;
  border: none;
  background: ${props => props.isAvailable ? (props.isSelected ? '#C4261E' : 'white') : '#f5f5f5'};
  color: ${props => props.isAvailable ? (props.isSelected ? 'white' : '#333') : '#999'};
  border-radius: 8px;
  cursor: ${props => props.isAvailable ? 'pointer' : 'not-allowed'};
  font-family: "Almarai", sans-serif;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.isAvailable && !props.isSelected ? '#f5f5f5' : undefined};
  }
`;

const TimeSlots = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const TimeSlot = styled.button`
  padding: 0.75rem;
  border: 2px solid ${props => props.isSelected ? '#C4261E' : '#ddd'};
  border-radius: 8px;
  background: white;
  color: ${props => props.isSelected ? '#C4261E' : '#333'};
  cursor: pointer;
  font-family: "Almarai", sans-serif;
  transition: all 0.2s;

  &:hover {
    border-color: #C4261E;
    color: #C4261E;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
`;

const Button = styled.button`
  padding: 0.75rem 2rem;
  border-radius: 8px;
  font-family: "Almarai", sans-serif;
  cursor: pointer;
  transition: all 0.2s;

  ${props => props.primary ? `
    background-color: #C4261E;
    color: white;
    border: none;

    &:hover {
      background-color: #A01E17;
    }
  ` : `
    background-color: white;
    color: #333;
    border: 1px solid #ddd;

    &:hover {
      border-color: #C4261E;
      color: #C4261E;
    }
  `}
`;

const DoctorInfo = styled.div`
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin-bottom: 2rem;
`;

const DoctorHeader = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`;

const DoctorImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
  border: 2px solid #eee;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DoctorDetails = styled.div`
  flex: 1;
  margin-${props => props.isRTL ? 'right' : 'left'}: 1.5rem;
`;

const DoctorName = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1.5rem;
  color: #333;
  font-family: "Almarai", sans-serif;
`;

const DoctorSpecialty = styled.p`
  margin: 0 0 0.5rem;
  color: #666;
  font-size: 1rem;
  font-family: "Almarai", sans-serif;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ffc107;
  margin-bottom: 0.5rem;

  span {
    color: #666;
    font-size: 0.9rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  margin-top: 0.5rem;
  font-family: "Almarai", sans-serif;

  svg {
    color: #C4261E;
    font-size: 1.2rem;
  }

  &:last-child {
    margin-top: 0.25rem;
  }
`;

const BranchName = styled.div`
  display: inline-block;
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  font-family: "Almarai", sans-serif;
`;

const Price = styled.div`
  font-size: 1.25rem;
  color: #28a745;
  font-weight: 500;
  font-family: "Almarai", sans-serif;
  margin-top: 1rem;
`;

const FormContainer = styled.div`
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin-bottom: 2rem;
`;

const FormTitle = styled.h3`
  color: #1B365D;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Almarai", sans-serif;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const FormLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #1B365D;
  margin-bottom: 0.5rem;
  font-family: "Almarai", sans-serif;
  
  svg {
    color: #C4261E;
    font-size: 1.2rem;
  }
`;

const FormInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  font-family: "Almarai", sans-serif;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};

  &:focus {
    outline: none;
    border-color: #C4261E;
    box-shadow: 0 0 0 2px rgba(196, 38, 30, 0.1);
  }

  &::placeholder {
    color: #999;
  }
`;

const PaymentContainer = styled.div`
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin-bottom: 2rem;
`;

const PaymentTitle = styled.h3`
  color: #1B365D;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Almarai", sans-serif;
`;

const PaymentOptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const PaymentOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: white;
  border: 2px solid ${props => props.selected ? '#C4261E' : '#ddd'};
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: #C4261E;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  svg {
    font-size: 2rem;
    color: ${props => props.selected ? '#C4261E' : '#1B365D'};
    margin-bottom: 1rem;
  }
`;

const PaymentOptionLabel = styled.span`
  color: #1B365D;
  font-family: "Almarai", sans-serif;
  font-size: 1rem;
  text-align: center;
`;

const BookingWizard = ({ isOpen, onClose, doctor }) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [patientDetails, setPatientDetails] = useState({
    name: '',
    phone: ''
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const handlePatientDetailsChange = (e) => {
    const { name, value } = e.target;
    setPatientDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
  };

  const steps = [
    { number: 1, label: t('bookingWizard.selectDoctor') },
    { number: 2, label: t('bookingWizard.selectDateTime') },
    { number: 3, label: t('bookingWizard.enterDetails') },
    { number: 4, label: t('bookingWizard.payment') }
  ];

  if (!isOpen) return null;

  const handleNext = () => {
    setCurrentStep(prev => Math.min(prev + 1, 4));
  };

  const handleBack = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  // Example available dates and times
  const availableDates = [22, 23, 24, 25, 26, 29, 30];
  const timeSlots = ['10:00 am', '12:00 pm', '01:00 pm'];

  const formatPrice = (price) => {
    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: 'SAR'
    }).format(price);
  };

  return (
    <Overlay onClick={onClose}>
      <WizardContainer isRTL={isRTL} onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose} isRTL={isRTL}>
          <FiX />
        </CloseButton>

        <StepsContainer>
          {steps.map((step) => (
            <Step key={step.number}>
              <StepNumber 
                active={currentStep === step.number}
                completed={currentStep > step.number}
              >
                {step.number}
              </StepNumber>
              <StepLabel
                active={currentStep === step.number}
                completed={currentStep > step.number}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </StepsContainer>

        {currentStep === 1 && doctor && (
          <DoctorInfo>
            <DoctorHeader>
              <DoctorImage>
                <img 
                  src={doctor.base64Image ? `data:image/jpeg;base64,${doctor.base64Image}` : '/images/default-doctor.jpg'} 
                  alt={doctor.name}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = '/images/default-doctor.jpg';
                  }}
                />
              </DoctorImage>
              <DoctorDetails isRTL={isRTL}>
                <DoctorName>{doctor.name}</DoctorName>
                <DoctorSpecialty>{doctor.specialty}</DoctorSpecialty>
                <Rating>
                  <AiFillStar />
                  <span>{doctor.rating} ({doctor.reviewCount} {t('doctorCard.reviews')})</span>
                </Rating>
                <InfoRow>
                  <FiMapPin />
                  <span>{doctor.branch}</span>
                </InfoRow>
                <InfoRow>
                  <FiClock />
                  <span>{t('bookingWizard.experience', { years: doctor.experience })}</span>
                </InfoRow>
                <BranchName>{doctor.branch}</BranchName>
                <Price>
                  {formatPrice(doctor.price)} {t('bookingWizard.consultationFee')}
                </Price>
              </DoctorDetails>
            </DoctorHeader>
          </DoctorInfo>
        )}

        {currentStep === 2 && (
          <>
            <CalendarContainer>
              <CalendarHeader>
                <NavigationButton>
                  {isRTL ? <FiChevronRight /> : <FiChevronLeft />}
                </NavigationButton>
                <MonthTitle>سبتمبر 2024</MonthTitle>
                <NavigationButton>
                  {isRTL ? <FiChevronLeft /> : <FiChevronRight />}
                </NavigationButton>
              </CalendarHeader>

              <WeekDays>
                <div>{t('bookingWizard.calendar.weekDays.friday')}</div>
                <div>{t('bookingWizard.calendar.weekDays.saturday')}</div>
                <div>{t('bookingWizard.calendar.weekDays.sunday')}</div>
                <div>{t('bookingWizard.calendar.weekDays.monday')}</div>
                <div>{t('bookingWizard.calendar.weekDays.tuesday')}</div>
                <div>{t('bookingWizard.calendar.weekDays.wednesday')}</div>
                <div>{t('bookingWizard.calendar.weekDays.thursday')}</div>
              </WeekDays>

              <DaysGrid>
                {Array.from({ length: 35 }, (_, i) => {
                  const day = i + 1;
                  const isAvailable = availableDates.includes(day);
                  return (
                    <DayCell
                      key={i}
                      isAvailable={isAvailable}
                      isSelected={selectedDate === day}
                      onClick={() => isAvailable && handleDateSelect(day)}
                    >
                      {day}
                    </DayCell>
                  );
                })}
              </DaysGrid>

              <TimeSlots>
                {timeSlots.map(time => (
                  <TimeSlot
                    key={time}
                    isSelected={selectedTime === time}
                    onClick={() => handleTimeSelect(time)}
                  >
                    {time}
                  </TimeSlot>
                ))}
              </TimeSlots>
            </CalendarContainer>
          </>
        )}

        {currentStep === 3 && (
          <FormContainer>
            <FormTitle>{t('bookingWizard.enterDetails')}</FormTitle>
            <FormGroup>
              <FormLabel>
                <FiUser />
                {t('bookingWizard.patientName')}
              </FormLabel>
              <FormInput
                type="text"
                name="name"
                value={patientDetails.name}
                onChange={handlePatientDetailsChange}
                placeholder={t('bookingWizard.patientNamePlaceholder')}
                isRTL={isRTL}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                <FiPhone />
                {t('bookingWizard.patientPhone')}
              </FormLabel>
              <FormInput
                type="tel"
                name="phone"
                value={patientDetails.phone}
                onChange={handlePatientDetailsChange}
                placeholder={t('bookingWizard.patientPhonePlaceholder')}
                isRTL={isRTL}
              />
            </FormGroup>
          </FormContainer>
        )}

        {currentStep === 4 && (
          <PaymentContainer>
            <PaymentTitle>{t('bookingWizard.payment')}</PaymentTitle>
            <PaymentOptionsGrid>
              <PaymentOption selected={selectedPaymentMethod === 'creditCard'}>
                <FiCreditCard />
                <PaymentOptionLabel onClick={() => handlePaymentMethodSelect('creditCard')}>{t('bookingWizard.paymentOptions.creditCard')}</PaymentOptionLabel>
              </PaymentOption>
              <PaymentOption selected={selectedPaymentMethod === 'cash'}>
                <FiDollarSign />
                <PaymentOptionLabel onClick={() => handlePaymentMethodSelect('cash')}>{t('bookingWizard.paymentOptions.cash')}</PaymentOptionLabel>
              </PaymentOption>
            </PaymentOptionsGrid>
          </PaymentContainer>
        )}

        <ButtonsContainer>
          {currentStep > 1 && (
            <Button onClick={handleBack}>
              {t('bookingWizard.back')}
            </Button>
          )}
          <Button primary onClick={handleNext}>
            {currentStep === 4 ? t('bookingWizard.finish') : t('bookingWizard.next')}
          </Button>
        </ButtonsContainer>
      </WizardContainer>
    </Overlay>
  );
};

export default BookingWizard;
