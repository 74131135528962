import React from "react";
import styled from "styled-components";
import DoctorCard from "../DoctorCard/DoctorCard";
import DoctorCardSkeleton from "../DoctorCard/DoctorCardSkeleton";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const GridContainer = styled.div`
  padding: 2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const PageButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.active ? '#C4261E' : '#ddd'};
  background: ${props => props.active ? '#C4261E' : '#fff'};
  color: ${props => props.active ? '#fff' : '#333'};
  border-radius: 4px;
  cursor: pointer;
  font-family: "Almarai", sans-serif;
  min-width: 40px;

  &:hover {
    background: ${props => props.active ? '#C4261E' : '#f8f9fa'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const NavigationButton = styled(PageButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    font-size: 1.2rem;
  }
`;

const DoctorGrid = ({ doctors, isRTL, currentPage, totalPages, onPageChange, loading }) => {
  const renderPaginationButtons = () => {
    const buttons = [];
    const maxVisibleButtons = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

    if (endPage - startPage + 1 < maxVisibleButtons) {
      startPage = Math.max(1, endPage - maxVisibleButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <PageButton
          key={i}
          active={currentPage === i}
          onClick={() => onPageChange(i)}
        >
          {i}
        </PageButton>
      );
    }

    return buttons;
  };

  const renderSkeletons = () => {
    return Array(8).fill(0).map((_, index) => (
      <DoctorCardSkeleton key={index} />
    ));
  };

  return (
    <GridContainer>
      <Grid>
        {loading ? renderSkeletons() : doctors.map((doctor, index) => (
          <DoctorCard key={doctor.id || index} doctor={doctor} isRTL={isRTL} />
        ))}
      </Grid>
      {!loading && (
        <Pagination isRTL={isRTL}>
          <NavigationButton
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {isRTL ? <BiChevronRight /> : <BiChevronLeft />}
          </NavigationButton>
          {renderPaginationButtons()}
          <NavigationButton
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {isRTL ? <BiChevronLeft /> : <BiChevronRight />}
          </NavigationButton>
        </Pagination>
      )}
    </GridContainer>
  );
};

export default DoctorGrid;
