import React from "react";
import InnerHeader from "../../components/InnerHeader/InnerHeader";
import Hero from "../../components/Hero/Hero";
import Partners from "../../components/Partners/Partners";
import About from "../../components/About/About";
import Services from "../../components/Services/Services";
import Booking from "../../components/Booking/Booking";
import Store from "../../components/Store/Store";
import Offers from "../../components/Offers/Offers";
import MedicalInfo from "../../components/MedicalInfo/MedicalInfo";
import HealthCare from "../../components/HealthCare/HealthCare";
import JoinUs from "../../components/JoinUs/JoinUs";
import Testimonials from "../../components/Testimonials/Testimonials";
import FAQ from "../../components/FAQ/FAQ";
import styled from "styled-components";

const MainPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainPage = () => {
  return (
    <MainPageContainer>
      <InnerHeader />
      <Hero />
      <About />
      <Services />
      <Booking />
      <Store />
      <Offers />
      <MedicalInfo />
      <HealthCare />
      <JoinUs />
      <Testimonials />
      <FAQ />
      <Partners />
    </MainPageContainer>
  );
};

export default MainPage;
