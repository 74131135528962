import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const StoreSection = styled.section`
margin: 4rem 0 0 0;
padding: 4rem 0;
background: #fff;
overflow: hidden;
  background: #F8F9FA;

`;
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h2`
  color: #C4261E;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Almarai', sans-serif;
`;

const Description = styled.p`
  color: #666;
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  font-family: 'Almarai', sans-serif;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;



const ProductCard = styled.div`
  background: #F2F4F5;
  border-radius: 16px;
  padding: 2rem;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  transition: transform 0.3s ease;
  border: none;
  box-shadow: none;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProductImage = styled.div`
  width: 64px;
  height: 64px;
  background: #183457;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ProductInfo = styled.div`
  padding: 0;
`;

const ProductTitle = styled.h3`
  color: #535766;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
`;

const ProductDescription = styled.p`
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
  font-family: 'Almarai', sans-serif;
  margin-bottom: 1rem;
`;

const ProductPrice = styled.div`
  color: #C4261E;
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
`;

const ReadMoreButton = styled.button`
  background: #183457;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 3rem auto 0;
  transition: transform 0.3s ease;
  font-family: 'Almarai', sans-serif;

  &:hover {
    transform: translateX(${props => props.isRTL ? '5px' : '-5px'});
  }

  svg {
    width: 20px;
    height: 20px;
    transform: ${props => props.isRTL ? 'rotate(0deg)' : 'rotate(180deg)'};
  }
`;

const Store = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Store/products?pageNumber=1&pageSize=3`);
        setProducts(response.data.items);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <StoreSection>
      <Container>
        <Header>
          <Title>{t('mainPage.store.title')}</Title>
          <Description>{t('mainPage.store.description')}</Description>
        </Header>
        <ProductGrid>
          {products.map((product) => (
            <ProductCard key={product.id} isRTL={i18n.language === 'ar'}>
              <ProductImage>
                <img 
                  src={`data:image/png;base64,${product.thumbnail}`} 
                  alt={product.title} 
                />
              </ProductImage>
              <ProductInfo>
                <ProductTitle>{product.title}</ProductTitle>
                <ProductDescription>{product.description}</ProductDescription>
                <ProductPrice>${product.price}</ProductPrice>
              </ProductInfo>
            </ProductCard>
          ))}
        </ProductGrid>
        <ReadMoreButton isRTL={isRTL}>
        <span>{t('mainPage.store.readMore')}</span>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </ReadMoreButton>
      </Container>
    </StoreSection>
  );
};

export default Store;
