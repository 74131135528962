import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import banner from '../../assets/register-banner.png'; // Using register banner for now
import { sendOTP, verifyOTP } from '../../utils/api';
import Notification from '../../components/Notification/Notification';

const LoginContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f7fb;
  padding: 2rem;
  padding-top: 0;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  font-family: 'Almarai', sans-serif;
`;

const LoginForm = styled.form`
  background: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Banner = styled.div`
  height: 150px;
  margin-bottom: 2rem;
  margin-top: -2.5rem;
  margin-right: -2.5rem;
  margin-left: -2.5rem;
  background: url(${banner}) no-repeat center center;
  background-size: cover;
  border-radius: 15px 15px 0 0;
`;

const Title = styled.h1`
  color: #183457;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.75rem;
`;

const InputGroup = styled.div`
  margin-bottom: 1.5rem;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
`;

const OTPGroup = styled(InputGroup)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2rem;
`;

const OTPInputGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 2rem 0;
`;

const SingleOTPInput = styled.input`
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  text-align: center;
  font-size: 1.2rem;
  background: #F8F9FA;

  &:focus {
    outline: none;
    border-color: #183457;
    background: white;
  }
`;

const ResendSection = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 1rem;
  color: ${props => props.canResend ? '#C4261E' : '#666'};
`;

const Timer = styled.span`
  color: #666;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #183457;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  padding-${props => props.isRTL ? 'right' : 'left'}: 2.5rem;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  font-size: 1rem;
  font-family: inherit;
  background-color: white;

  &:focus {
    outline: none;
    border-color: #183457;
  }

  &::placeholder {
    color: #A0A0A0;
  }
`;

const OTPInput = styled.input`
  width: 200px;
  padding: 0.75rem;
  text-align: center;
  letter-spacing: 8px;
  font-size: 1.2rem;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin-top: 0.5rem;
  font-family: inherit;
  background-color: white;

  &:focus {
    outline: none;
    border-color: #183457;
  }
`;

const InputIcon = styled.div`
  position: absolute;
  ${props => props.isRTL ? 'right' : 'left'}: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #A0A0A0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  width: 100%;
  background-color: #183457;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  font-family: inherit;
  margin-top: 1rem;

  &:hover {
    background-color: #122843;
  }
`;

const ResendButton = styled.button`
  background: none;
  border: none;
  color: #C4261E;
  margin-top: 1rem;
  cursor: pointer;
  font-family: inherit;
  
  &:hover {
    text-decoration: underline;
  }
`;

const RegisterSection = styled.div`
  margin-top: 2rem;
  text-align: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;

const RegisterText = styled.span`
  color: #183457;
`;

const RegisterLink = styled.a`
  color: #C4261E;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Login = () => {
  const [formData, setFormData] = useState({
    identifier: '',
    phone: '',
  });
  const [otp, setOtp] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [timer, setTimer] = useState(59);
  const [canResend, setCanResend] = useState(false);
  const [notification, setNotification] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isRTL = i18n.language === 'ar';

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const showNotification = (msgId, type) => {
    setNotification({
      message: t(msgId),
      type
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleOTPChange = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOTP = otp.split('');
      newOTP[index] = value;
      setOtp(newOTP.join(''));
      
      if (value && index < 5) {
        const nextInput = e.target.parentElement.children[index + 1];
        nextInput?.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      const prevInput = e.target.parentElement.children[index - 1];
      prevInput?.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!showOTP) {
      const result = await sendOTP(formData.phone);
      if (result.success) {
        showNotification(result.msgId, 'success');
        setShowOTP(true);
        setTimer(59);
        setCanResend(false);
      } else {
        showNotification(result.msgId, 'error');
      }
    } else {
      if (otp.length === 6) {
        const result = await verifyOTP(formData.phone, otp, formData.identifier);
        if (result.success) {
          showNotification(result.msgId, 'success');
          setTimeout(() => {
            // Check if there's a redirect URL saved
            const redirectUrl = localStorage.getItem('redirectAfterLogin');
            if (redirectUrl) {
              localStorage.removeItem('redirectAfterLogin');
              navigate(redirectUrl);
            } else {
              navigate('/');
            }
          }, 1000);
        } else {
          if (result.needsRegistration) {
            showNotification(result.msgId, 'warning');
            setTimeout(() => {
              navigate('/register');
            }, 2000);
          } else {
            showNotification(result.msgId, 'error');
          }
        }
      }
    }
  };

  const handleResendOTP = async () => {
    if (canResend) {
      const result = await sendOTP(formData.phone);
      if (result.success) {
        showNotification(result.msgId, 'success');
        setTimer(59);
        setCanResend(false);
      } else {
        showNotification(result.msgId, 'error');
      }
    }
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <LoginContainer isRTL={isRTL}>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
        />
      )}
      <LoginForm onSubmit={handleSubmit}>
        <Banner />
        <Title>{t('login.title')}</Title>
        {!showOTP ? (
          <>
            <InputGroup isRTL={isRTL}>
              <Label>{t('login.identifier')}</Label>
              <InputWrapper isRTL={isRTL}>
                <InputIcon isRTL={isRTL}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 20V19C4 16.2386 6.23858 14 9 14H15C17.7614 14 20 16.2386 20 19V20M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </InputIcon>
                <Input
                  type="text"
                  name="identifier"
                  value={formData.identifier}
                  onChange={handleChange}
                  placeholder={t('login.identifierPlaceholder')}
                  required
                  isRTL={isRTL}
                />
              </InputWrapper>
            </InputGroup>
            <InputGroup isRTL={isRTL}>
              <Label>{t('login.phone')}</Label>
              <InputWrapper isRTL={isRTL}>
                <InputIcon isRTL={isRTL}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 5C3 3.89543 3.89543 3 5 3H8.27924C8.70967 3 9.09181 3.27543 9.22792 3.68377L10.7257 8.17721C10.8831 8.64932 10.6694 9.16531 10.2243 9.38787L7.96701 10.5165C9.06925 12.9612 11.0388 14.9308 13.4835 16.033L14.6121 13.7757C14.8347 13.3306 15.3507 13.1169 15.8228 13.2743L20.3162 14.7721C20.7246 14.9082 21 15.2903 21 15.7208V19C21 20.1046 20.1046 21 19 21H18C9.71573 21 3 14.2843 3 6V5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </InputIcon>
                <Input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder={t('login.phonePlaceholder')}
                  required
                  isRTL={isRTL}
                />
              </InputWrapper>
            </InputGroup>
          </>
        ) : (
          <OTPGroup>
            <Label>{t('login.otpMessage')} {formData.phone}</Label>
            <OTPInputGroup>
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <SingleOTPInput
                  key={index}
                  type="text"
                  maxLength="1"
                  value={otp[index] || ''}
                  onChange={(e) => handleOTPChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  autoFocus={index === 0}
                />
              ))}
            </OTPInputGroup>
            <ResendSection canResend={canResend}>
              <span>{t('login.resendCode')}</span>
              {!canResend && <Timer>{String(timer).padStart(2, '0')}:00</Timer>}
              {canResend && (
                <ResendButton type="button" onClick={handleResendOTP}>
                  {t('login.resendOTP')}
                </ResendButton>
              )}
            </ResendSection>
          </OTPGroup>
        )}
        <Button type="submit">
          {showOTP ? t('login.verifyOTP') : t('login.submit')}
        </Button>
        <RegisterSection>
          <RegisterText>{t('login.noAccount')}</RegisterText>
          <RegisterLink onClick={handleRegister}>{t('login.register')}</RegisterLink>
        </RegisterSection>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
