import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { 
  FaSnapchat, 
  FaYoutube, 
  FaInstagram, 
  FaTwitter, 
  FaFacebookF 
} from 'react-icons/fa';
import logo from '../../assets/logo.svg';

const FooterContainer = styled.footer`
  background-color: #fff;
  padding: 4rem 0 0 0;
  font-family: 'Almarai', sans-serif;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 2rem;
  text-align: ${props => props.isRTL ? 'right' : 'left'};

  @media (max-width: 968px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const Column = styled.div``;

const Logo = styled.img`
  height: 50px;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const Title = styled.h3`
  color: #183457;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const LinkItem = styled.li`
  margin-bottom: 0.8rem;
`;

const Link = styled.a`
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #C4261E;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: ${props => props.isRTL ? 'flex-start' : 'flex-end'};
`;

const SocialIcon = styled.a`
  color: #183457;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #C4261E;
  }
`;

const BottomBar = styled.div`
  background-color: #183457;
  color: white;
  padding: 1rem 0;
  margin-top: 3rem;
  text-align: center;
  font-size: 0.9rem;
`;

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;

  a {
    color: white;
    text-decoration: none;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const Footer = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer isRTL={isRTL}>
      <FooterContent isRTL={isRTL}>
        <Column>
          <Logo src={logo} alt="تداوي" />
          <Description>
            {t('footer.description')}
          </Description>
          <Title>{t('footer.follow-us')}</Title>
          <SocialLinks isRTL={isRTL}>
            <SocialIcon href="#" aria-label="Snapchat">
              <FaSnapchat />
            </SocialIcon>
            <SocialIcon href="#" aria-label="YouTube">
              <FaYoutube />
            </SocialIcon>
            <SocialIcon href="#" aria-label="Instagram">
              <FaInstagram />
            </SocialIcon>
            <SocialIcon href="#" aria-label="Twitter">
              <FaTwitter />
            </SocialIcon>
            <SocialIcon href="#" aria-label="Facebook">
              <FaFacebookF />
            </SocialIcon>
          </SocialLinks>
        </Column>

        <Column>
          <Title>{t('footer.quick-links')}</Title>
          <LinkList>
            <LinkItem><Link href="#">{t('footer.home')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.about')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.medical-services')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.book-appointment')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.branches')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.home-care')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.store')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.careers')}</Link></LinkItem>
          </LinkList>
        </Column>

        <Column>
          <Title>{t('footer.additional-links')}</Title>
          <LinkList>
            <LinkItem><Link href="#">{t('footer.medical-info')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.faq')}</Link></LinkItem>
          </LinkList>
        </Column>

        <Column>
          <Title>{t('footer.customer-support')}</Title>
          <LinkList>
            <LinkItem><Link href="#">{t('footer.complaints')}</Link></LinkItem>
            <LinkItem><Link href="#">{t('footer.support-center')}</Link></LinkItem>
          </LinkList>
        </Column>
      </FooterContent>

      <BottomBar>
        <BottomLinks>
          <Link href="#">{t('footer.privacy-policy')}</Link>
          <Link href="#">{t('footer.terms')}</Link>
        </BottomLinks>
        <div>{t('footer.copyright')} {currentYear}</div>
      </BottomBar>
    </FooterContainer>
  );
};

export default Footer;
