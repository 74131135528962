import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Add axios interceptor to add token to all requests
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const sendOTP = async (phoneNumber) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/send-otp`, {
      phoneNumber
    });
    return {
      success: response.data.status === 'success',
      message: response.data.message,
      msgId: response.data.msgId
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Failed to send verification code',
      msgId: error.response?.data?.msgId || 'msg.otpsentfailed'
    };
  }
};

export const verifyOTP = async (phoneNumber, otp, idNumberOrResidenceNumber) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/verify-otp`, {
      phoneNumber,
      otp: parseInt(otp, 10),
      idNumberOrResidenceNumber
    });
    
    if (response.data.token && response.data.role === 'Client') {
      // Store token and user info only if role is Client
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('userId', response.data.userId);
      localStorage.setItem('role', response.data.role);
      
      return {
        success: true,
        data: response.data,
        msgId: 'msg.otpverify'
      };
    } else if (response.data.token && response.data.role !== 'Client') {
      // If role is not Client, return error
      return {
        success: false,
        message: 'Invalid user role',
        msgId: 'msg.invalidRole'
      };
    }
    
    return {
      success: false,
      message: 'Verification failed',
      msgId: 'msg.otpverifyfailed'
    };
  } catch (error) {
    if (error.response?.status === 401 && error.response?.data?.message === 'User is not active') {
      return {
        success: false,
        message: error.response.data.message,
        msgId: 'msg.userNotActive',
        needsRegistration: true
      };
    }

    return {
      success: false,
      message: error.response?.data?.message || 'Failed to verify OTP',
      msgId: error.response?.data?.msgId || 'msg.otpverifyfailed'
    };
  }
};

export const completeRegistration = async (registrationData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/auth/register-client`,
      registrationData
    );
    
    return {
      success: true,
      data: response.data,
      msgId: 'msg.registrationcomplete'
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Failed to complete registration',
      msgId: error.response?.data?.msgId || 'msg.registrationfailed'
    };
  }
};
