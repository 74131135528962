import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Section = styled.section`
  padding: 60px 0;
  background-color: #fff;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #E31E24;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #000;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 4/3;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    
    .overlay {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem;
  transition: background-color 0.3s ease;
  color: #fff;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
`;

const CardDescription = styled.p`
  font-size: 1rem;
  opacity: 0.9;
  line-height: 1.4;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
`;

const LoadingWrapper = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const ErrorWrapper = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #E31E24;
  text-align: center;
`;

const ErrorMessage = styled.p`
  font-size: 1.1rem;
  margin-bottom: 1rem;
`;

const RetryButton = styled.button`
  background-color: #E31E24;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c41a1f;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #E31E24;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const LoadingText = styled.p`
  color: #666;
  font-size: 1.1rem;
`;

const HealthCare = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isRTL = i18n.language === 'ar';
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchServices = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/blog/healthcare?pageNumber=1&pageSize=3`
      );
      if (response.data && response.data.items) {
        setServices(response.data.items);
      }
    } catch (error) {
      console.error('Error fetching healthcare services:', error);
      setError(t('healthCare.error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/healthcare/${id}`);
  };

  if (loading) {
    return (
      <Section>
        <Container>
          <Title>{t('healthCare.title')}</Title>
          <Description>{t('healthCare.description')}</Description>
          <LoadingWrapper>
            <Spinner />
            <LoadingText>{t('healthCare.loading')}</LoadingText>
          </LoadingWrapper>
        </Container>
      </Section>
    );
  }

  if (error) {
    return (
      <Section>
        <Container>
          <Title>{t('healthCare.title')}</Title>
          <Description>{t('healthCare.description')}</Description>
          <ErrorWrapper>
            <ErrorMessage>{error}</ErrorMessage>
            <RetryButton onClick={fetchServices}>
              {t('healthCare.retry')}
            </RetryButton>
          </ErrorWrapper>
        </Container>
      </Section>
    );
  }

  return (
    <Section>
      <Container>
        <Title>{t('healthCare.title')}</Title>
        <Description>{t('healthCare.description')}</Description>
        <Grid>
          {services.map((service) => (
            <Card 
              key={service.id} 
              onClick={() => handleCardClick(service.id)}
            >
              <CardImage 
                src={`data:image/jpeg;base64,${service.image}`} 
                alt={service.title} 
              />
              <Overlay className="overlay">
                <CardTitle isRTL={isRTL}>{service.title}</CardTitle>
                <CardDescription isRTL={isRTL}>
                  {service.description.replace(/[*#\n-]/g, '')}
                </CardDescription>
              </Overlay>
            </Card>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default HealthCare;
