import React from "react";
import styled, { keyframes } from "styled-components";
import { FaStethoscope } from "react-icons/fa";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 1.5rem;
`;

const SpinnerContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
`;

const Circle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-top-color: #C4261E;
  border-radius: 50%;
  animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #C4261E;
  animation: ${pulse} 1.2s ease-in-out infinite;
  
  svg {
    font-size: 2rem;
  }
`;

const Text = styled.div`
  color: #666;
  font-family: "Almarai", sans-serif;
  font-size: 1.1rem;
  text-align: center;
  animation: ${pulse} 1.2s ease-in-out infinite;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const LoadingSpinner = ({ text, isRTL }) => {
  return (
    <Container>
      <SpinnerContainer>
        <Circle />
        <IconContainer>
          <FaStethoscope />
        </IconContainer>
      </SpinnerContainer>
      <Text isRTL={isRTL}>{text}</Text>
    </Container>
  );
};

export default LoadingSpinner;
