import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #183457;
  color: white;
  padding: 1.5rem;
  z-index: 1000;
  transform: translateY(${props => props.show ? '0' : '100%'});
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: ${props => props.isRTL ? 'right' : 'left'};

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Message = styled.p`
  margin: 0;
  line-height: 1.6;
  font-size: 0.95rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  white-space: nowrap;

  &.primary {
    background: #C4261E;
    color: white;
    &:hover {
      background: #a61f18;
    }
  }

  &.secondary {
    background: transparent;
    color: white;
    border: 1px solid white;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &.tertiary {
    background: transparent;
    color: #ccc;
    text-decoration: underline;
    padding: 0.8rem 1rem;
    &:hover {
      color: white;
    }
  }
`;

const CookieConsent = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Check if user has already made a choice
    const cookieChoice = localStorage.getItem('cookieConsent');
    if (!cookieChoice) {
      // Show the banner after a short delay
      const timer = setTimeout(() => setShow(true), 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsent', 'all');
    setShow(false);
  };

  const handleCustomize = () => {
    // Here you would typically open a modal with detailed cookie preferences
    localStorage.setItem('cookieConsent', 'custom');
    setShow(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'essential');
    setShow(false);
  };

  if (!show) return null;

  return (
    <Container show={show}>
      <Content isRTL={isRTL}>
        <Message>{t('cookies.message')}</Message>
        <ButtonGroup>
          <Button className="primary" onClick={handleAcceptAll}>
            {t('cookies.acceptAll')}
          </Button>
          <Button className="secondary" onClick={handleCustomize}>
            {t('cookies.customize')}
          </Button>
          <Button className="tertiary" onClick={handleReject}>
            {t('cookies.reject')}
          </Button>
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default CookieConsent;
