import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getFAQs } from '../../services/faqService';

const Section = styled.section`
  padding: 80px 0;
  background-color: #fff;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h2`
  color: #C41E3A;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const Description = styled.p`
  text-align: center;
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const FAQList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 900px;
  margin: 0 auto;
`;

const FAQItem = styled.div`
  border: 1px solid #eee;
  border-radius: 12px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const Question = styled.button`
  width: 100%;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  padding: 20px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #1B365D;
  font-size: 1.1rem;
  font-weight: 500;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};

  svg {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
    transform: ${props => props.isOpen ? 'rotate(180deg)' : 'none'};
    margin-${props => props.isRTL ? 'left' : 'right'}: 15px;
    order: ${props => props.isRTL ? '-1' : '1'};
  }
`;

const Answer = styled.div`
  padding: ${props => props.isOpen ? '0 20px 20px' : '0 20px'};
  color: #666;
  line-height: 1.6;
  max-height: ${props => props.isOpen ? '1000px' : '0'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  overflow: hidden;
  transition: all 0.3s ease;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(27, 54, 93, 0.1);
  border-radius: 50%;
  border-top-color: #1B365D;
  animation: spin 1s ease-in-out infinite;
  margin: 20px auto;

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #C41E3A;
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  border-radius: 8px;
  background-color: rgba(196, 30, 58, 0.1);
`;

const ChevronIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const [openIndex, setOpenIndex] = useState(null);
  const [faqItems, setFaqItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        setLoading(true);
        const data = await getFAQs();
        setFaqItems(data.items);
        setError(null);
      } catch (err) {
        setError(t('faq.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchFAQs();
  }, [t]);

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Section>
      <Container>
        <Title isRTL={isRTL}>{t('faq.title')}</Title>
        <Description isRTL={isRTL}>{t('faq.description')}</Description>
        
        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <FAQList>
            {faqItems.map((item, index) => (
              <FAQItem key={item.id}>
                <Question
                  isRTL={isRTL}
                  isOpen={openIndex === index}
                  onClick={() => toggleQuestion(index)}
                >
                  {item.question}
                  <ChevronIcon />
                </Question>
                <Answer isRTL={isRTL} isOpen={openIndex === index}>
                  {item.answer}
                </Answer>
              </FAQItem>
            ))}
          </FAQList>
        )}
      </Container>
    </Section>
  );
};

export default FAQ;
