import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import departmentsIcon from '../../assets/services/departments.png';
import homecareIcon from '../../assets/services/homecare.png';
import facilitiesIcon from '../../assets/services/facilities.png';

const ServicesSection = styled.section`
  padding: 4rem 0;
  background: #fff;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  color: #C4261E;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
`;

const Description = styled.p`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
  font-family: 'Almarai', sans-serif;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const ServiceCard = styled.div`
  background: #F2F4F5;
  border-radius: 16px;
  padding: 2rem;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  background: #183457;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const CardTitle = styled.h3`
  color: ${props => props.color};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
`;

const CardDescription = styled.p`
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
  font-family: 'Almarai', sans-serif;
`;

const Services = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  const services = [
    {
      icon: departmentsIcon,
      title: {
        text: t('mainPage.services.departments.title'),
        color: '#535766',
      },
      description: t('mainPage.services.departments.description')
    },
    {
      icon: homecareIcon,
      title: {
        text: t('mainPage.services.homecare.title'),
        color: '#535766',
      },
      description: t('mainPage.services.homecare.description')
    },
    {
      icon: facilitiesIcon,
      title: {
        text: t('mainPage.services.facilities.title'),
        color: '#C4261E',
      },
      description: t('mainPage.services.facilities.description')
    }
  ];

  return (
    <ServicesSection>
      <Container>
        <Title>{t('mainPage.services.title')}</Title>
        <Description>{t('mainPage.services.description')}</Description>
        <ServicesGrid>
          {services.map((service, index) => (
            <ServiceCard key={index} isRTL={isRTL}>
              <IconContainer>
                <img src={service.icon} alt={service.title.text} />
              </IconContainer>
              <CardTitle color={service.title.color}>{service.title.text}</CardTitle>
              <CardDescription>{service.description}</CardDescription>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </Container>
    </ServicesSection>
  );
};

export default Services;
