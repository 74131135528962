import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import doctorImage from '../../assets/booking/doctor-booking.png';
import { FaUserMd } from 'react-icons/fa';

const BookingSection = styled.section`
margin: 4rem 0 0 0;
padding: 4rem 0;
background: #fff;
overflow: hidden;
  background: #F8F9FA;

`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  gap: 4rem;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
  background: #F6F9FC;

  @media (max-width: 968px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  position: relative;
  border-radius: 0 100px 0  100px;
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    #C4261E 55%,
    transparent 55%,
    transparent 60%,
    #183457 60%
  );
  
  img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }
`;

const Content = styled.div`
  flex: 1;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
`;

const Title = styled.h2`
  color: #C4261E;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
`;

const Description = styled.p`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  font-family: 'Almarai', sans-serif;
`;

const Process = styled.p`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  font-family: 'Almarai', sans-serif;
`;

const BookButton = styled.button`
  background: #183457;
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Almarai', sans-serif;
  font-weight: bold;

  &:hover {
    background: #122843;
    transform: translateY(-2px);
  }
`;

const Badge = styled.div`
  position: absolute;
  top: 30%;
  ${props => props.isRTL ? 'left' : 'right'}: 0;
  background: white;
  color: #183457;
  padding: 0.75rem 1.5rem;
  border-radius: 16px;
  font-size: 1rem;
  z-index: 40;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-family: 'Almarai', sans-serif;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  font-weight: 600;
`;

const IconWrapper = styled.div`
  background: #F2F4F5;
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Booking = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  return (
    <BookingSection>
      <Container isRTL={isRTL}>
        
        <Content isRTL={isRTL}>
          <Title>{t('mainPage.booking.title')}</Title>
          <Description>{t('mainPage.booking.description')}</Description>
          <Process>{t('mainPage.booking.process')}</Process>
          <BookButton>{t('mainPage.booking.button')}</BookButton>
        </Content>

        <ImageWrapper>
          <Badge isRTL={isRTL}>
            <IconWrapper>
              <FaUserMd size={20} color="#183457" />
            </IconWrapper>
            {t('mainPage.booking.badge')}
          </Badge>
          <img src={doctorImage} alt="Doctor" />
        </ImageWrapper>
      </Container>
    </BookingSection>
  );
};

export default Booking;
