import React, { useState } from "react";
import styled from "styled-components";
import { AiFillStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import BookingWizard from '../BookingWizard/BookingWizard';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: inherit;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 75%;
  overflow: hidden;
  background-color: #f5f5f5;
`;

const DoctorImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${Card}:hover & {
    transform: scale(1.05);
  }
`;

const ContentContainer = styled.div`
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const DoctorName = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  color: #333;
  font-family: "Almarai", sans-serif;
  margin-bottom: 0.5rem;
`;

const Specialty = styled.p`
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  font-family: "Almarai", sans-serif;
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const StarIcon = styled(AiFillStar)`
  color: #FFD700;
  font-size: 1.2rem;
`;

const Rating = styled.span`
  color: #666;
  font-size: 0.9rem;
  font-family: "Almarai", sans-serif;
`;

const ReviewCount = styled.span`
  color: #999;
  font-size: 0.8rem;
  font-family: "Almarai", sans-serif;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const InfoLabel = styled.span`
  color: #999;
  font-size: 0.8rem;
  font-family: "Almarai", sans-serif;
`;

const InfoValue = styled.span`
  color: #333;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Almarai", sans-serif;
`;

const NextAvailable = styled.div`
  background-color: #f8f8f8;
  padding: 0.75rem;
  border-radius: 8px;
  text-align: center;
  margin-top: auto;
`;

const NextAvailableText = styled.p`
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  font-family: "Almarai", sans-serif;
`;

const CardActions = styled.div`
  padding: 1rem;
  border-top: 1px solid #ddd;
`;

const BookButton = styled.button`
  background-color: #4CAF50;
  color: #fff;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Almarai", sans-serif;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #45a049;
  }
`;

const DoctorCard = ({ doctor, isRTL }) => {
  const { t } = useTranslation();
  const [isBookingOpen, setIsBookingOpen] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return date.toLocaleString(isRTL ? 'ar-SA' : 'en-US', options);
  };

  return (
    <Card isRTL={isRTL}>
      <ImageContainer>
        <DoctorImage 
          src={`data:image/jpeg;base64,${doctor.base64Image}`} 
          alt={doctor.name} 
          onError={(e) => {
            e.target.src = '/images/default-doctor.jpg';
          }}
        />
      </ImageContainer>
      <ContentContainer>
        <DoctorName>{doctor.name}</DoctorName>
        <Specialty>{doctor.specialty}</Specialty>
        <RatingContainer>
          <StarIcon />
          <Rating>{doctor.rating}</Rating>
          <ReviewCount>({doctor.reviewCount} {t('doctorCard.reviews')})</ReviewCount>
        </RatingContainer>
        <InfoGrid>
          <InfoItem>
            <InfoLabel>{t('doctorCard.branch')}</InfoLabel>
            <InfoValue>{doctor.branch}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>{t('doctorCard.degree')}</InfoLabel>
            <InfoValue>{doctor.degree}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>{t('doctorCard.price')}</InfoLabel>
            <InfoValue>{doctor.price} {t('doctorCard.currency')}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>{t('doctorCard.type')}</InfoLabel>
            <InfoValue>{doctor.type}</InfoValue>
          </InfoItem>
        </InfoGrid>
        {doctor.availability && doctor.availability.nextAvailable && (
          <NextAvailable>
            <NextAvailableText>
              {t('doctorCard.nextAvailable')}: {formatDate(doctor.availability.nextAvailable)}
            </NextAvailableText>
          </NextAvailable>
        )}
        <CardActions>
          <BookButton onClick={() => setIsBookingOpen(true)}>
            {t('doctorCard.bookNow')}
          </BookButton>
        </CardActions>
        <BookingWizard 
          isOpen={isBookingOpen}
          onClose={() => setIsBookingOpen(false)}
          doctor={doctor}
        />
      </ContentContainer>
    </Card>
  );
};

export default DoctorCard;
