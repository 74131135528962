import React, { useState, useEffect } from "react";
import InnerHeader from "../../components/InnerHeader/InnerHeader";
import PageNavigation from "../../components/PageNavigation/PageNavigation";
import BookingFilter from "../../components/BookingFilter/BookingFilter";
import DoctorGrid from "../../components/DoctorGrid/DoctorGrid";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getDoctors, getFilters } from "../../services/api";
import { BiSearch } from "react-icons/bi";

const BookingPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0;
  font-family: "Almarai", sans-serif;
  margin-top: 4rem;
  text-align: ${props => props.isRTL ? "right" : "left"};
  margin-${props => props.isRTL ? "right" : "left"}: 2.5rem;
  color: #C4261E;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-family: "Almarai", sans-serif;
  color: #C4261E;
  text-align: center;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const SearchContainer = styled.div`
  margin: 0 2.5rem;
  margin-bottom: 2rem;
`;

const SearchInput = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 400px;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};

  svg {
    color: #666;
    font-size: 1.2rem;
    margin: ${props => props.isRTL ? '0 0 0 0.5rem' : '0 0.5rem 0 0'};
  }

  input {
    border: none;
    outline: none;
    width: 100%;
    font-family: "Almarai", sans-serif;
    font-size: 1rem;
    color: #333;

    &::placeholder {
      color: #999;
    }
  }
`;

const BookingPage = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [filterOptions, setFilterOptions] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 8
  });
  const [filters, setFilters] = useState({
    search: "",
    branch: "",
    degree: "",
    price: "",
    type: ""
  });

  const fetchFilters = async () => {
    try {
      const data = await getFilters();
      setFilterOptions(data);
    } catch (err) {
      console.error('Error fetching filters:', err);
    }
  };

  const fetchDoctors = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getDoctors({
        page: pagination.currentPage,
        limit: pagination.limit,
        ...filters
      });

      setDoctors(response.doctors);
      setPagination(response.pagination);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchDoctors();
  }, [pagination.currentPage, filters]);

  const handleFilterChange = (type, value) => {
    if (type === 'reset') {
      setFilters({
        search: "",
        branch: "",
        degree: "",
        price: "",
        type: ""
      });
      setPagination(prev => ({ ...prev, currentPage: 1 }));
    } else {
      // For price, send the range directly
      setFilters(prev => ({
        ...prev,
        [type]: value
      }));
      setPagination(prev => ({ ...prev, currentPage: 1 }));
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setFilters(prev => ({
      ...prev,
      search: value
    }));
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  };

  const handlePageChange = (page) => {
    setPagination(prev => ({
      ...prev,
      currentPage: page
    }));
  };

  if (error) {
    return (
      <BookingPageContainer>
        <InnerHeader />
        <PageNavigation currentPage={t("bookingPage.pageTitle")} />
        <ErrorContainer isRTL={isRTL}>{error}</ErrorContainer>
      </BookingPageContainer>
    );
  }

  return (
    <BookingPageContainer>
      <InnerHeader />
      <PageNavigation currentPage={t("bookingPage.pageTitle")} />
      <Title isRTL={isRTL}>{t("bookingPage.title")}</Title>
      <SearchContainer>
        <SearchInput isRTL={isRTL}>
          <BiSearch />
          <input
            type="text"
            placeholder={t("bookingPage.searchPlaceholder")}
            value={filters.search}
            onChange={handleSearch}
          />
        </SearchInput>
      </SearchContainer>
      <BookingFilter 
        filters={filters}
        filterOptions={filterOptions}
        onFilterChange={handleFilterChange}
        isRTL={isRTL}
      />
      {loading ? (
        <LoadingSpinner text={t("common.loading")} isRTL={isRTL} />
      ) : (
        <DoctorGrid 
          doctors={doctors} 
          isRTL={isRTL}
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          onPageChange={handlePageChange}
          hasNextPage={pagination.hasNextPage}
          hasPreviousPage={pagination.hasPreviousPage}
        />
      )}
    </BookingPageContainer>
  );
}; 

export default BookingPage;
