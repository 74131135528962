import React from "react";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SkeletonBase = styled.div`
  background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
  background-size: 800px 100px;
  animation: ${shimmer} 1.2s linear infinite forwards;
`;

const ImageSkeleton = styled(SkeletonBase)`
  width: 100%;
  height: 200px;
`;

const ContentContainer = styled.div`
  padding: 1rem;
`;

const RatingSkeleton = styled(SkeletonBase)`
  width: 120px;
  height: 20px;
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const NameSkeleton = styled(SkeletonBase)`
  width: 180px;
  height: 24px;
  margin-bottom: 0.8rem;
  border-radius: 4px;
`;

const SpecialtySkeleton = styled(SkeletonBase)`
  width: 150px;
  height: 18px;
  border-radius: 4px;
`;

const DoctorCardSkeleton = () => {
  return (
    <Card>
      <ImageSkeleton />
      <ContentContainer>
        <RatingSkeleton />
        <NameSkeleton />
        <SpecialtySkeleton />
      </ContentContainer>
    </Card>
  );
};

export default DoctorCardSkeleton;
