import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';

const MedicalInfoSection = styled.section`
  padding: 40px 0;
  background-color: #fff;
  color: #000;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #E31E24;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #000;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  margin-bottom: 2rem;
  opacity: 0.8;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled(Link)`
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  color: #000;
  margin-bottom: 0.75rem;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  font-weight: bold;
  line-height: 1.4;
`;

const CardDescription = styled.p`
  color: #666;
  font-size: 0.9rem;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  line-height: 1.6;
  margin-bottom: 1rem;
  flex: 1;
`;

const ReadMore = styled.span`
  display: inline-block;
  color: #E31E24;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: underline;
  margin-top: auto;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
`;

const ViewMoreButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 0;
  padding: 0.75rem 2rem;
  background: transparent;
  border: 2px solid #E31E24;
  color: #E31E24;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: #E31E24;
    color: #fff;
  }
`;

const MedicalInfo = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/blog/medicalinfo?pageNumber=1&pageSize=3`);
        if (response.data && response.data.items) {
          setArticles(response.data.items);
        }
      } catch (error) {
        console.error('Error fetching medical info articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return null; // Or a loading spinner
  }

  return (
    <MedicalInfoSection>
      <Container>
        <Title isRTL={isRTL}>{t('medicalInfo.title')}</Title>
        <Description isRTL={isRTL}>{t('medicalInfo.description')}</Description>
        <Grid>
          {articles.map((article) => (
            <Card key={article.id} to={`/medical-info/${article.id}`}>
              <CardImage 
                src={`data:image/jpeg;base64,${article.image}`} 
                alt={article.title} 
              />
              <CardContent>
                <CardTitle isRTL={isRTL}>{article.title}</CardTitle>
                <CardDescription isRTL={isRTL}>
                  {article.description.replace(/[*#\n-]/g, '')}
                </CardDescription>
                <ReadMore isRTL={isRTL}>{t('medicalInfo.readMore')}</ReadMore>
              </CardContent>
            </Card>
          ))}
        </Grid>
        <ViewMoreButton to="/medical-info">
          {t('medicalInfo.viewMore')}
        </ViewMoreButton>
      </Container>
    </MedicalInfoSection>
  );
};

export default MedicalInfo;
