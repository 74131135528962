import React from 'react';
import styled from 'styled-components';
import { BiMessageRoundedDots } from 'react-icons/bi';

const FloatingButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #C4261E;
  border: none;
  box-shadow: 0 4px 12px rgba(196, 38, 30, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1000;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(196, 38, 30, 0.4);
    background-color: #a62019;
  }

  svg {
    color: white;
    font-size: 28px;
  }
`;

const ChatButton = () => {
  const handleClick = () => {
    // Add chat functionality here
    console.log('Chat button clicked');
  };

  return (
    <FloatingButton onClick={handleClick} aria-label="فتح المحادثة">
      <BiMessageRoundedDots />
    </FloatingButton>
  );
};

export default ChatButton;
